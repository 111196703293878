import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-conteudo',
  template: `
    <div class="container-tooltip-info">
      <h2 id="title-tooltip-info" *ngIf="title">{{ title }}</h2>
      <span id="text-tooltip-info">{{ text }}</span>
    </div>
  `,
  styleUrls: ['./tooltip-conteudo.component.scss'],
  imports: [NgIf],
  standalone: true
})
export class TooltipConteudoComponent {
  @Input() title;
  @Input() text;

  constructor() {}
}
