import { loadMessages, locale } from 'devextreme/localization';
import * as ptMessage from 'devextreme/localization/messages/pt.json';
import {
  GoogleAnalyticsService,
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import { ToastModule } from 'primeng/toast';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';
import { MainComponent } from 'src/app/shared/components/main/main.component';
import { ModalCartaCorrecaoComponent } from 'src/app/shared/components/modais/modal-carta-correcao/modal-carta-correcao.component';
import { ModalCustomGridConfirmacao } from 'src/app/shared/components/modais/modal-custom-grid-confirmacao/modal-custom-grid-confirmacao';
import { ModalEtiquetaSelecaoComponent } from 'src/app/shared/components/modais/modal-etiqueta-selecao/modal-etiqueta-selecao.component';
import { ModalGridLegendComponent } from 'src/app/shared/components/modais/modal-legenda-grid/modal-grid-legend.component';
import { ModalManifestacaoVisualizacaoJustificativaComponent } from 'src/app/shared/components/modais/modal-manifestacao-visualizacao-justificativa/modal-manifestacao-visualizacao-justificativa.component';
import { ModalMasterSelecaoComponent } from 'src/app/shared/components/modais/modal-master-selecao/modal-master-selecao.component';
import { ModalNfeAnaliseProdutoComponent } from 'src/app/shared/components/modais/modal-nfe-analise-produto/modal-nfe-analise-produto.component';
import { ModalNfeAnaliseComponent } from 'src/app/shared/components/modais/modal-nfe-analise/modal-nfe-analise.component';
import { ModalNfeConfirmacaoManifestacaoComponent } from 'src/app/shared/components/modais/modal-nfe-confirmacao-manifestacao/modal-nfe-confirmacao-manifestacao.component';
import { ModalNfeImpressaoComponent } from 'src/app/shared/components/modais/modal-nfe-impressao/modal-nfe-impressao.component';
import { ModalNfeLogManifestacaoComponent } from 'src/app/shared/components/modais/modal-nfe-log-manifestacao/modal-nfe-log-manifestacao.component';
import { ModalNfeResumoManifestacaoComponent } from 'src/app/shared/components/modais/modal-nfe-resumo-manifestacao/modal-nfe-resumo-manifestacao.component';
import { ModalNfeXmlComponent } from 'src/app/shared/components/modais/modal-nfe-xml/modal-nfe-xml.component';
import { ModalProdutoRelatorioAvancadoComponent } from 'src/app/shared/components/modais/modal-produto-relatorio-avancado/modal-produto-relatorio-avancado.component';
import { ModalSefazSituacaoComponent } from 'src/app/shared/components/modais/modal-sefaz-situacao/modal-sefaz-situacao.component';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { filter } from 'rxjs';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="isLoggedIn">
      <app-main *ngIf="!openSetup"></app-main>
      <ng-container *ngIf="openSetup">
        <router-outlet></router-outlet>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isLoggedIn">
      <router-outlet></router-outlet>
    </ng-container>

    <app-loading-page></app-loading-page>

    <ng-container *ngIf="isLoggedIn">
      <app-modal-nfe-impressao></app-modal-nfe-impressao>
      <app-modal-nfe-analise></app-modal-nfe-analise>
      <app-modal-nfe-xml></app-modal-nfe-xml>
      <app-modal-nfe-log-manifestacao></app-modal-nfe-log-manifestacao>
      <app-modal-nfe-analise-produto></app-modal-nfe-analise-produto>
      <app-modal-carta-correcao></app-modal-carta-correcao>
      <app-modal-legenda-grid></app-modal-legenda-grid>
      <app-modal-sefaz-situacao></app-modal-sefaz-situacao>
      <app-modal-manifestacao-visualizacao-justificativa></app-modal-manifestacao-visualizacao-justificativa>
      <app-modal-nfe-confirmacao-manifestacao></app-modal-nfe-confirmacao-manifestacao>
      <app-modal-nfe-resumo-manifestacao></app-modal-nfe-resumo-manifestacao>
      <app-modal-custom-grid-confirmacao></app-modal-custom-grid-confirmacao>
      <app-modal-etiqueta-selecao></app-modal-etiqueta-selecao>
      <app-modal-master-selecao></app-modal-master-selecao>
      <app-modal-produto-relatorio-avancado></app-modal-produto-relatorio-avancado>
    </ng-container>
    <p-toast
      [baseZIndex]="10000"
      [preventOpenDuplicates]="true"
      key="global-toast"></p-toast>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ToastModule,
    NgxGoogleAnalyticsRouterModule,
    RouterOutlet,
    MainComponent,
    LoadingPageComponent,
    ModalNfeImpressaoComponent,
    ModalNfeAnaliseComponent,
    ModalNfeXmlComponent,
    ModalNfeLogManifestacaoComponent,
    ModalNfeAnaliseProdutoComponent,
    ModalCartaCorrecaoComponent,
    ModalGridLegendComponent,
    ModalSefazSituacaoComponent,
    ModalManifestacaoVisualizacaoJustificativaComponent,
    ModalNfeConfirmacaoManifestacaoComponent,
    ModalNfeResumoManifestacaoComponent,
    ModalCustomGridConfirmacao,
    ModalEtiquetaSelecaoComponent,
    ModalMasterSelecaoComponent,
    ModalProdutoRelatorioAvancadoComponent
  ]
})
export class AppComponent implements OnInit {
  title = 'confear-web-front';
  isLoggedIn = true;
  externalPage: boolean;
  externalRoutes = ['alterar-senha', 'esqueci-minha-senha', '/'];
  openSetup = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private titleService: Title
  ) {
    loadMessages(ptMessage);
    locale(navigator.language);
  }

  ngOnInit() {
    this.listenUser();
    this.sendGa();
  }

  sendGa() {
    if (!environment.production) {
      this.gaService.set({ traffic_type: 'internoegtec' });
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.gaService.pageView(event?.url, this.titleService.getTitle());
      });
  }

  listenUser() {
    this.authService.getUserObservable().subscribe(user => {
      if (user?.refreshTokenExpired) {
        this.isLoggedIn = false;
        this.router.navigate(['login'], {
          queryParams: { tokenExpired: true }
        });
      } else if (user?.sessionDuplicated) {
        this.isLoggedIn = false;
        this.router.navigate(['login']);
      } else {
        this.isLoggedIn = !!user;
        this.openSetup = !user?.indSetupInicialConcluido;
        this.externalPage = this.externalRoutes.some(route => {
          return (this.externalPage = this.router?.url === route);
        });

        if (!this.isLoggedIn && !this.externalPage) {
          this.router.navigate(['login']);
        }
      }
    });
  }
}
