import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-modal-log-activation-entity',
  templateUrl: './modal-log-activation-entity.component.html',
  styleUrls: ['./modal-log-activation-entity.component.scss']
})
export class ModalLogActivationEntityComponent implements OnDestroy, OnChanges {
  @Output() modalResponse = new EventEmitter();
  @Input() open: boolean;
  @Input() listLog: any;
  @Input() textInfo: string;
  dataSource: any;
  title = 'Log de Ativação/Inativação';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.open) {
      this.dataSource = this.listLog?.dados;
      console.log(this.dataSource);
    }
  }

  close() {
    this.modalResponse.emit({ open: false });
  }

  ngOnDestroy() {
    this.close();
  }
}
