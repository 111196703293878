import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-wrapping',
  templateUrl: './page-wrapping.component.html',
  styleUrls: ['./page-wrapping.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class PageWrappingComponent {
  @Input() pageTitle: string;
  @Input() height = '100%';
  @Input() maxHeight = 'auto';
  @Input() hasTitleContainer = true;
  @Input() overflowX = 'auto';
  @Input() overflowY = 'auto';
  @Input() classList = '';
  @Input() styleList = '';

  constructor() {}
}
