import { Button } from 'primeng/button';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { ModalOptions } from 'src/app/core/models/interfaceObjects/modal/modal-options';
import { ModalService } from 'src/app/core/services/modal/modal.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription, filter } from 'rxjs';

import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-custom-grid-confirmacao',
  template: `
    <app-modal
      width="350px"
      [modalTitle]="modalTitle"
      [removeTitleMargin]="false"
      (closeModal)="close()"
      [display]="open">
      <section class="container-modal-grid-confirmation">
        <span class="text-center mb-4">{{ ask }}</span>
        <section class="container-buttons">
          <p-button
            (click)="cancel()"
            label="Não"
            class=" btn-vermelho mr-2"
            icon="pi pi-times"></p-button>
          <p-button
            (click)="confirm()"
            label="Sim"
            class="btn-verde"
            icon="pi pi-check"></p-button>
        </section>
      </section>
    </app-modal>
  `,
  standalone: true,
  imports: [ModalComponent, Button],
  styleUrls: ['./modal-custom-grid-confirmacao.scss']
})
export class ModalCustomGridConfirmacao implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  subscription: Subscription[] = [];
  ask = 'Deseja confirmar a exclusão da GRID PERSONALIZADA salva?';
  private modalName: string;
  data: any;
  modalTitle = 'Personalização da Grid';

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const observable: Observable<ModalOptions> = this.modalService
      .listenChanges()
      .pipe(
        filter(
          res =>
            res.name === 'MODAL_CONFIRMACAO_EXCLUSAO_GRID' ||
            res.name === 'MODAL_CONFIRMACAO_SOBRESCRITA_GRID' ||
            res.name === 'MODAL_CONFIRMACAO_EXCLUSAO_ALERTAS'
        )
      );
    const subscription = observable.subscribe((res: ModalOptions) => {
      this.modalName = res?.name ? res?.name : '';
      this.modalTitle = res?.data?.title ? res?.data?.title : this.modalTitle;
      this.open = true;
      this.ask = res.data?.message;
      this.data = res?.data;
    });
    this.subscription.push(subscription);
  }

  close() {
    this.open = false;
    this.modalName = undefined as any;
    this.ask = undefined as any;
    this.loading = false;
    this.data = undefined as any;
  }

  confirm() {
    this.modalService.answer({
      id: CONFEAR_PERMISSIONS.TODOS,
      name: this.modalName,
      data: {
        confirm: true,
        idFuncionalidade: this.data.idFuncionalidade
      }
    });
    this.close();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  cancel() {
    this.modalService.answer({
      id: CONFEAR_PERMISSIONS.TODOS,
      name: this.modalName,
      data: {
        confirm: false,
        idFuncionalidade: this.data.idFuncionalidade
      }
    });
    this.close();
  }
}
