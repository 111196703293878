import { StatusSefazEnum } from '@core/enums/status-sefaz.enum';
import { StatusSefaz } from '@core/models/entities/status-sefaz/status-sefaz';
import { User } from '@core/models/entities/user/user';
import { AuthService } from '@core/services/auth/auth.service';
import { CompanyService } from '@core/services/company/company.service';
import { DrawerFiltersService } from '@core/services/drawer-filters/drawer-filters.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { NfeService } from '@core/services/nfe/nfe.service';
import { DrawerComponent } from '@shared/components/drawer/drawer.component';
import { IconeComponent } from '@shared/components/icone/icone.component';
import { ModalUsuarioConfigSetupComponent } from '@shared/components/modais/modal-usuario-config-setup/modal-usuario-config-setup.component';
import { PageWrappingComponent } from '@shared/components/page-wrapping/page-wrapping.component';
import { TooltipConteudoComponent } from '@shared/components/tooltip-conteudo/tooltip-conteudo.component';
import { StatusSefazDirective } from '@shared/directives/status-sefaz/status-sefaz.directive';
import { TimePipe } from '@shared/pipes/time/time.pipe';
import {
  DxButtonModule,
  DxDataGridModule,
  DxProgressBarModule,
  DxTooltipModule
} from 'devextreme-angular';
import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Message } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';

import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subscription, distinct, lastValueFrom } from 'rxjs';

import { InicioFiltrosComponent } from './components/inicio-filtros/inicio-filtros.component';
import { ModalInicioInfoComponent } from './components/modal-inicio-info/modal-inicio-info.component';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PageWrappingComponent,
    DxDataGridModule,
    DxProgressBarModule,
    DxButtonModule,
    DxTooltipModule,
    StatusSefazDirective,
    TimePipe,
    Button,
    DrawerComponent,
    MultiSelectModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    InicioFiltrosComponent,
    TooltipConteudoComponent,
    ModalUsuarioConfigSetupComponent,
    IconeComponent,
    Message,
    ModalInicioInfoComponent
  ]
})
export class InicioComponent implements OnInit, OnDestroy {
  filterValues: any;
  dataSource: any;
  countNfe: number;
  pageTitle: string;
  statuSefazList: StatusSefaz[];
  readonly statusSefazOtimo = StatusSefazEnum.OTIMO;
  readonly statusNotaNaoCaptando: 2;
  withTemplateVisible: boolean;
  @ViewChildren(IconeComponent, { read: ElementRef })
  icons: QueryList<ElementRef>;
  currentTooltip: any;
  toggleVisibileSefaz: boolean;
  subscription: Subscription[] = [];
  filterVisible: boolean;
  loggedUser: User;
  openModalInfo: boolean;

  constructor(
    private companyService: CompanyService,
    private nfeService: NfeService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private drawerFiltersService: DrawerFiltersService
  ) {}

  async ngOnInit() {
    await this.getLoggedUser();
    this.listenDrawerFilterActions();
    this.listenUserLogged();
    this.sendAuthorizationNewUser();

    //inicialização de modal informativo
    // this.openModalInfo = true;
  }

  sendAuthorizationNewUser() {
    this.route.queryParams.subscribe(async param => {
      if (param.hasOwnProperty('hashAutorizacao')) {
        const response = await lastValueFrom(
          this.authService.authorizeExistingUser(param['hashAutorizacao'])
        );

        if (response?.mensagem) {
          const user = await lastValueFrom(
            this.authService.refreshToken(this.authService.getUserValue())
          );

          if (user) {
            this.authService.setSession(user);
          }

          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Sucesso',
            key: 'global-toast',
            detail: response?.mensagem
          });
        }
      }
    });
  }

  async reload() {
    this.loadingService.open();
    await this.getCompanies();
    await this.getStatusSefaz();
    this.loadingService.close();
  }

  listenUserLogged() {
    const subscription = this.authService
      .getUserObservable()
      .pipe(distinct(res => res?.apiKey))
      .subscribe(async res => {
        if (res) {
          if (!res.indAdministradorSistema) {
            await this.reload();
          }
        }
      });

    this.subscription.push(subscription);
  }

  async getStatusSefaz() {
    const response = await lastValueFrom(this.nfeService.listStatusSefaz());
    this.statuSefazList = response.dados as StatusSefaz[];
  }

  async getCompanies() {
    this.countNfe = 0;
    const response = await lastValueFrom(
      this.loggedUser.indAdministradorSistema
        ? this.companyService.listByUsersCnpjs(
            true,
            this.filterValues.idUsuariosMaster != null
              ? this.filterValues.idUsuariosMaster.join(',')
              : '',
            this.filterValues.cnpjEmpresas != null
              ? this.filterValues.cnpjEmpresas
              : ''
          )
        : this.companyService.list()
    );

    this.dataSource = response?.dados?.map(company => {
      this.countNfe += company?.totalNfeArmazenadas;

      const { diasExpiracao, tipoCertificado } = company;
      const max = tipoCertificado === 'A1' ? 365 : 1095;
      const perc = (100 * (max - diasExpiracao)) / max;

      this.pageTitle = ` ${
        response?.paginacao?.totalDeRegistro
      } empresa(s) - ${this.thousandSeparator(
        this.countNfe
      )} notas fiscais armazenadas`;
      return { ...company, progress: perc };
    });
  }

  thousandSeparator(value): string {
    const pattern = /(\d)(?=(\d{3})+(?!\d))/g;
    const repl = '$1.';
    const valueString = String(value);
    return valueString.replace(pattern, repl);
  }

  calculateProgressBarValue(row): number {
    const { diasExpiracao, tipoCertificado } = row?.data;
    const max = tipoCertificado === 'A1' ? 365 : 1095;
    return (100 * (max - diasExpiracao)) / max;
  }

  formatBarLabel() {
    return ``;
  }

  formatLabelProgressbar(row): string {
    const { diasExpiracao, dataValidade } = row?.data;
    const stringDate = new Date(dataValidade).toLocaleDateString();
    return diasExpiracao > 0
      ? `Validade: ${stringDate} (faltam ${diasExpiracao} dias)`
      : `Expirado em ${stringDate} `;
  }

  refreshDataGrid(): void {
    this.getCompanies();
  }

  toggleWithTemplate(data) {
    const { id } = data;
    this.currentTooltip = undefined;
    const elementArray = this.icons.toArray();
    this.currentTooltip = elementArray.find(el => el.nativeElement.id == id);
    this.withTemplateVisible = !this.withTemplateVisible;
  }

  toggleWithTemplateSefaz() {
    this.toggleVisibileSefaz = !this.toggleVisibileSefaz;
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  toggleDrawerFilter() {
    this.filterVisible = !this.filterVisible;
  }

  listenDrawer(event) {
    this.filterVisible = event.visible;
  }

  async listenFilterValues(filters: any) {
    this.filterValues = filters;
    await this.reload();
    this.drawerFiltersService.close();
  }

  send = () => this.drawerFiltersService.send();

  reset = () => this.drawerFiltersService.reset();
  openModalUpdateUserRegister: boolean;

  async getLoggedUser() {
    this.authService.getUserObservable().subscribe(user => {
      if (user) {
        this.loggedUser = user;
        this.openModalUpdateUserRegister =
          this.loggedUser.indSolicitaRecadastro;
      }
    });
  }

  listenDrawerFilterActions() {
    this.drawerFiltersService.receive().subscribe(action => {
      if (action?.close) {
        this.filterVisible = false;
      }
    });
  }
}
