import { Step } from 'src/app/core/models/interfaceObjects/step/step';
import { StepService } from 'src/app/core/services/step/step.service';

import { NgClass, NgComponentOutlet, NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps',
  template: `
    <div class="container">
      <div *ngFor="let step of steps" class="step">
        <span
          [style.width]="calcWidth()"
          *ngIf="!step.isFirst"
          class="line"></span>
        <div class="container-circle">
          <span
            class="circle"
            [ngClass]="step.isVisited ? 'visited' : 'notVisited'"
            [class.current]="step.isCurrent">
            {{ step.order }}
          </span>
          <span class="title">{{ step.label }}</span>
        </div>
        <span *ngIf="!step.isLast" class="line"></span>
      </div>
    </div>
    <ng-content></ng-content>
    <ng-container *ngComponentOutlet="currentStep.component"></ng-container>
  `,
  standalone: true,
  imports: [NgForOf, NgClass, NgIf, NgComponentOutlet],
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  @Input() steps: Step[];
  currentStep: Step;

  constructor(private stepService: StepService) {}

  ngOnInit() {
    this.stepService.listenCurrent().subscribe(current => {
      this.currentStep = current;
    });
  }

  calcWidth() {
    return `calc(${100 / this.steps.length}vw)`;
  }
}
