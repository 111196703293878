import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { markAllFields } from '../../../core/utils/utilsForm/form';

@Component({
  selector: 'app-modal-active-entity',
  templateUrl: './modal-active-entity.component.html',
  styleUrls: ['./modal-active-entity.component.scss']
})
export class ModalActiveEntity implements OnInit, OnDestroy {
  @Input() open: boolean;
  @Input() entity: any;
  @Output() resModal = new EventEmitter();
  @Input() reasons: any[];
  @Input() modalTitle;
  @Input() textInfo;
  form: FormGroup;

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      motivo: [null, Validators.required],
      observacao: ['']
    });
  }

  async submit() {
    markAllFields(this.form, 'dirty');
    const { motivo, observacao } = this.form.value;
    const formData = {
      id: this.entity?.id,
      idMotivo: motivo?.id,
      indAtivo: this.entity?.indAtivo,
      observacao
    };
    if (this.form.valid) {
      this.resModal.emit({ formData, close: true });
      this.clear();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Campo obrigatório',
        key: 'global-toast',
        sticky: true,
        detail: 'Escolha um motivo para realizar a inativação/ativação'
      });
    }
  }

  close() {
    this.clear();
    this.resModal.emit({ close: true });
  }

  clear() {
    this.form.reset({
      motivo: null,
      observacao: ''
    });
    this.entity = undefined as any;
    this.reasons = [];
  }

  ngOnDestroy() {
    this.clear();
  }
}
