import { DrawerFiltersService } from '@core/services/drawer-filters/drawer-filters.service';
import { MessageService } from 'primeng/api';
import { InputText } from 'primeng/inputtext';
import { MultiSelect } from 'primeng/multiselect';
import { UsuarioSistema } from 'src/app/core/models/entities/user/usuario-sistema';
import { AuthService } from 'src/app/core/services/auth/auth.service';

import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inicio-filtros',
  template: `
    <section *ngIf="form" class="p-fluid grid formgrid mt-2" [formGroup]="form">
      <div class="lg:col-12 flex">
        <div class="input-group field lg:col-6 md:col-6 col-12">
          <label for="emitente">Usuarios Master</label>
          <div class="input-group field container-periodo-button">
            <p-multiSelect
              [selectedItemsLabel]="'{0} itens selecionados'"
              optionLabel="nomeLogin"
              optionValue="id"
              id="usuarioMaster"
              [virtualScrollItemSize]="30"
              [virtualScroll]="true"
              [options]="usuariosMaster"
              formControlName="idUsuariosMaster"></p-multiSelect>
          </div>
        </div>
      </div>
      <div class="lg:col-6 flex">
        <div class="input-group field lg:col-12 md:col-6 col-12">
          <label for="empresa">Empresas</label>
          <div class="input-group field container-periodo-button">
            <input
              formControlName="cnpjEmpresas"
              id="empresa"
              type="text"
              pInputText />
          </div>
        </div>
      </div>
    </section>
  `,
  imports: [MultiSelect, ReactiveFormsModule, NgIf, InputText],
  standalone: true
})
export class InicioFiltrosComponent implements OnInit {
  @Input() usuariosMaster: UsuarioSistema[];
  @Output() filters = new EventEmitter();
  form: FormGroup;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private drawerFiltersService: DrawerFiltersService,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    this.buildForm();
    await this.listUsuarioMaster();
    this.listenDrawerFilterActions();
  }

  listenDrawerFilterActions() {
    this.drawerFiltersService.receive().subscribe(action => {
      if (action?.send) {
        this.send();
      }

      if (action?.reset) {
        this.reset();
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      idUsuariosMaster: [],
      cnpjEmpresas: []
    });
  }

  async listUsuarioMaster() {
    const response = await lastValueFrom(
      this.authService.listSistemUserByGroup('1')
    );
    if (response?.dados) {
      this.usuariosMaster = response?.dados;
    }
  }

  send() {
    let errors: string[] = [];
    if (this.form?.errors) {
      errors = Object.values(this.form.errors);
    }
    if (this.form.valid) {
      this.filters.emit(this.form.value);
      this.drawerFiltersService.close();
    } else {
      errors.forEach(error => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Campos obrigatórios',
          key: 'global-toast',
          sticky: true,
          detail: error
        });
      });
    }
  }

  reset() {
    this.form.reset({ idUsuariosMaster: [], cnpjEmpresas: [] });
  }
}
