<app-modal
  [blockScroll]="true"
  [hasLineHeader]="false"
  [width]="'90vw'"
  (closeModal)="close()"
  [display]="open">
  <section header>NF-e {{ nfe?.numeroNfe }} para impressão</section>
  <app-loading-fragment [loading]="loading"></app-loading-fragment>
  <section class="container-pdf">
    <section id="container-nfe-option">
      <p-button
        class="btn-download-pdf"
        icon="pi pi-file-pdf"
        label="BAIXAR PDF"
        (click)="download()"></p-button>

      <div class="container-info">
        <span class="vertical-line"></span>
        <em class="pi pi-info-circle"></em>
        Para realizar uma busca no documento abaixo, pressione F3 e digite a
        palavra que gostaria de procurar no documento.
      </div>
    </section>
    <object
      class="pdf-content"
      [data]="secureUrlPdf | safe: 'resourceUrl'"
      type="application/pdf"></object>
  </section>
</app-modal>
