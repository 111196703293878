import { User } from 'src/app/core/models/entities/user/user';
import { AuthService } from 'src/app/core/services/auth/auth.service';

import { Component, OnInit, Renderer2 } from '@angular/core';

import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  template: `
    <section id="container-footer">
      <a class="link-logo" href="https://www.egtec.com.br" target="_blank">
        <img
          src="../../../../assets/logos/egtec-branco-pq-rodape.png"
          id="logoEgtecRodape"
          alt="CONFEAR é um produto EGTEC"
          title="CONFEAR é um produto EGTEC" />
      </a>
    </section>
  `,
  standalone: true,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  user: User;

  constructor(
    private authService: AuthService,
    private render: Renderer2
  ) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.authService.getUserObservable().subscribe(response => {
      if (response) {
        this.user = response;
      }
    });
  }
}
