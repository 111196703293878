import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, find, of } from 'rxjs';

import { Step } from '../../models/interfaceObjects/step/step';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  private $steps: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>([]);
  private $current: BehaviorSubject<Step>;

  constructor() {}

  set steps(steps: Step[]) {
    this.$steps.next(steps);
    this.$current = new BehaviorSubject<Step>(steps[0]);
  }

  get steps(): Step[] {
    return this.$steps.value;
  }

  listenSteps(): Observable<Step[]> {
    return this.$steps.asObservable();
  }

  listenCurrent(): Observable<Step> {
    return this.$current.asObservable();
  }

  getCurrentValue(): Step {
    return this.$current.value;
  }

  set current(current: Step) {
    let steps = this.$steps.value;

    steps.forEach(step => {
      if (step.order < current.order) {
        step.isCurrent = false;
        step.isVisited = true;
      }
      step.isCurrent = false;
    });
    current.isCurrent = true;
    this.$current.next(current);
  }

  next() {
    if (this.$current.value) {
      let { isLast, order } = this.$current.value;

      if (!isLast) {
        let nextOrder = order + 1;
        const next = this.find(nextOrder);

        if (next) {
          this.current = next;
        }
      }
    }
  }

  previous() {
    if (this.$current.value) {
      let { isFirst, order, isCurrent } = this.$current.value;

      if (!isFirst) {
        let previousOrder = order - 1;
        const previous = this.find(previousOrder);

        if (previous) {
          this.current = previous;
        }
      }
    }
  }

  find(order: number): Step | undefined {
    return this.steps.find(step => {
      return step.order === order;
    });
  }

  setData(data: any) {
    this.$current.value.data = data;
  }
}
