import { MessageService } from 'primeng/api';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { ModalService } from 'src/app/core/services/modal/modal.service';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';
import { ProdutoGridRelatorioAvancadoComponent } from '../../produto-grid-relatorio-avancado/produto-grid-relatorio-avancado.component';

@Component({
  selector: 'app-modal-produto-relatorio-avancado',
  template: `
    <app-modal (closeModal)="close()" [display]="open" [width]="'90vw'">
      <section header>Relatório Avançado por Produto</section>
      <app-loading-fragment [loading]="loading"></app-loading-fragment>
      <ng-container *ngIf="open">
        <app-produto-grid-relatorio-avancado
          (dataLoading)="listenDataLoading($event)"
          [nfes]="nfes"></app-produto-grid-relatorio-avancado>
      </ng-container>
      <div class="warning text-red mt-3">
        Caso alguns produtos não tenham sido exibidos, tente novamente mais
        tarde, pois as NF-e mais recentes podem estar em processo de indexação.
      </div>
    </app-modal>
  `,
  standalone: true,
  styleUrls: ['./modal-produto-relatorio-avancado.component.scss'],
  imports: [
    CommonModule,
    ModalComponent,
    LoadingFragmentComponent,
    ProdutoGridRelatorioAvancadoComponent
  ]
})
export class ModalProdutoRelatorioAvancadoComponent
  implements OnInit, OnDestroy
{
  open: boolean;
  loading: boolean;
  data: any;
  nfes: string[];
  subscription: Subscription[] = [];

  constructor(
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.RELATORIO_AVANCADO_PRODUTO &&
        res.open
      ) {
        this.resetField(res.data);
        if (res?.data?.selectedRows?.length) {
          this.loading = true;
          this.open = true;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Atenção',
            key: 'global-toast',
            sticky: true,
            detail: 'Por favor, selecione pelo menos uma nota fiscal.'
          });
        }
      }
    });

    this.subscription.push(subscription);
  }

  resetField(data) {
    this.data = undefined;
    setTimeout(() => {
      this.data = data;
      if (this.data?.selectedRows?.length) {
        this.nfes = this.data?.selectedRows.map(nfe => {
          return nfe?.id?.toString();
        });
      }
    }, 1000);
  }

  close() {
    this.loading = false;
    this.open = false;
  }

  listenDataLoading(loaded: boolean) {
    this.loading = false;
    this.open = loaded;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  listenData(event: any) {}
}
