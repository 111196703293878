import { ProgressBar } from 'primeng/progressbar';
import { LoadingService } from 'src/app/core/services/loading/loading.service';

import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-page',
  template: `
    <section *ngIf="display" id="container-loading-page">
      <img
        id="logo-confear"
        src="../../../../assets/logos/logo-confear-branco.png"
        alt="logo confear" />
      <p-progressBar
        [style]="{ height: '30px', width: '400px' }"
        mode="indeterminate"></p-progressBar>
    </section>
  `,
  styleUrls: ['./loading-page.component.scss'],
  standalone: true,
  imports: [ProgressBar, NgIf]
})
export class LoadingPageComponent implements OnInit {
  display: boolean;
  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.listen().subscribe((display: boolean) => {
      this.display = display;
    });
  }
}
