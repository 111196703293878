import { CONFEAR_PERMISSIONS } from '@core/consts/CONFEAR-PERMISSIONS';
import { User } from '@core/models/entities/user/user';
import { AuthService } from '@core/services/auth/auth.service';
import { MenuService } from '@core/services/menu/menu.service';
import { ModalService } from '@core/services/modal/modal.service';
import { NotificationDropdownService } from '@core/services/notification-dropdown/notification-dropdown.service';
import { MenuItem } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  imports: [TieredMenu, RouterLink, CommonModule, NgOptimizedImage],
  standalone: true
})
export class TopbarComponent implements OnInit {
  itemsUserMenu: MenuItem[] = [
    {
      id: '1',
      label: 'Alterar master',
      icon: 'pi pi-user',
      command: () => this.openModalSelectMaster()
    },
    {
      id: '2',
      label: 'Alterar Dados Cadastrais',
      icon: 'pi pi-user-edit',
      command: () => this.router.navigate(['alterar-dados-usuario'])
    },
    {
      id: '3',
      label: 'Alterar senha',
      icon: 'pi pi-lock-open',
      command: () => this.router.navigate(['alterar-senha-logado'])
    },
    {
      id: '4',
      label: 'Minhas Faturas',
      icon: 'pi pi-receipt',
      command: () => this.router.navigate(['faturas'])
    },
    {
      id: '5',
      label: 'Sair',
      icon: 'pi pi-sign-out',
      command: () => this.exit()
    }
  ];

  user: User;
  dropdownDisplay: boolean;
  menuDisplay: boolean;
  private MODAL_CHANGE_USER_MASTER = 'MODAL_CHANGE_USER_MASTER';
  masterLogged: any;
  env;
  readonly MASTER = 1;
  readonly GESTOR = 2;

  constructor(
    private authService: AuthService,
    private notificationDropdownService: NotificationDropdownService,
    private menuService: MenuService,
    private modalService: ModalService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.env = environment;
    await this.getUser();
    this.listenDisplayNotification();
    this.listenMenu();
  }

  async getUser() {
    this.authService.getUserObservable().subscribe(response => {
      if (response) {
        this.user = response;
        this.masterLogged = User.getLoggedMaster(this.user);
        if (!this.user?.indPossuiMultiplosMaster) {
          this.itemsUserMenu = this.itemsUserMenu.filter(
            item => item.id !== '1'
          );
        }
        if (
          this.user.grupo[0]?.id !== this.GESTOR &&
          this.user.grupo[0]?.id !== this.MASTER
        ) {
          this.itemsUserMenu = this.itemsUserMenu.filter(
            item => item.id !== '4'
          );
        }
      }
    });
  }

  listenDisplayNotification() {
    this.notificationDropdownService.getDisplay().subscribe(display => {
      this.dropdownDisplay = display;
    });
  }

  listenMenu() {
    this.menuService
      .getDisplayMenuObservable()
      .subscribe((display: boolean) => {
        this.menuDisplay = display;
      });
  }

  toggleNotification() {
    this.notificationDropdownService[this.dropdownDisplay ? 'close' : 'open']();
  }

  toggleMenu() {
    this.menuService[this.menuDisplay ? 'close' : 'open']();
  }

  exit() {
    this.authService.logout().subscribe();
  }

  openModalSelectMaster() {
    if (this.user?.indPossuiMultiplosMaster) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.TODOS,
        name: this.MODAL_CHANGE_USER_MASTER,
        open: true,
        data: null
      });
    }
  }
}
