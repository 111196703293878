import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../../core/models/entities/company/company';
import { CompanyDeleteReason } from '../../../core/models/entities/company/company-delete-reason';
import { CompanyDeleteRequest } from '../../../core/models/requests/company-delete-request ';
import { CompanyService } from '../../../core/services/company/company.service';

@Component({
  selector: 'app-modal-delete-company',
  templateUrl: './modal-delete-company.component.html',
  styleUrls: ['./modal-delete-company.component.scss']
})
export class ModalDeleteCompanyComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() open = true;
  @Input() company: Company;
  @Output() modalResponse = new EventEmitter();
  deleteReasons: CompanyDeleteReason[];
  form: FormGroup;

  constructor(
    private companyService: CompanyService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.open) {
      await this.getReasons();
    }
  }

  async getReasons() {
    const response = await lastValueFrom(
      this.companyService.listDeleteReasons()
    );
    if (response?.dados) {
      this.deleteReasons = response.dados;
    }
  }

  buildForm() {
    this.form = this.fb.group({
      motivoExclusao: [null, Validators.required],
      observacoes: [null],
      indSolicitaEnvioNotas: [false]
    });
  }

  close() {
    this.clear();
    this.modalResponse.emit({ open: false });
  }

  async delete() {
    const { cnpj } = this.company;
    const { motivoExclusao, observacoes, indSolicitaEnvioNotas } =
      this.form.value;
    const object: CompanyDeleteRequest = {
      cnpj: [cnpj],
      idMotivoExclusao: motivoExclusao.id,
      observacoes,
      motivoExclusao: motivoExclusao.descricao,
      indSolicitaEnvioNotas
    };

    if (this.form.valid) {
      const response = await lastValueFrom(this.companyService.delete(object));
      if (response?.dados) {
        this.modalResponse.emit(response?.dados);
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Campo obrigatório',
        key: 'global-toast',
        sticky: true,
        detail: 'Você precisa escolher um motivo para a exclusão da empresa'
      });
    }
  }

  clear() {
    this.form.reset();
    this.open = false;
    this.company = undefined as any;
    this.deleteReasons = [];
  }

  ngOnDestroy() {
    this.clear();
  }
}
