import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';
import XmlBeautufy from 'xml-beautify';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription, lastValueFrom } from 'rxjs';

import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-nfe-xml',
  template: `
    <app-modal
      [overflowYHidden]="true"
      [blockScroll]="true"
      [hasLineHeader]="false"
      [width]="'90vw'"
      (closeModal)="close()"
      [display]="open">
      <section header>Abrir XML NF-e {{ nfe?.numeroNfe }}</section>
      <app-loading-fragment [loading]="loading"></app-loading-fragment>
      <section class="container-xml">
        <section class="container-buttons-xml" id="container-nfe-option">
          <p-button
            (click)="download()"
            label="BAIXAR XML"
            class="btn-download-xml"
            icon="pi pi-file-excel"></p-button>

          <div class="container-info">
            <span class="vertical-line"></span>
            <em class="pi pi-info-circle"></em>
            Para realizar uma busca no documento abaixo, pressione F3 e digite a
            palavra que gostaria de procurar no documento.
          </div>
        </section>
        <div class="content-xml">
          <pre lang="xml">{{ xml }}</pre>
        </div>
      </section>
    </app-modal>
  `,
  standalone: true,
  imports: [ModalComponent, LoadingFragmentComponent, Button],
  styleUrls: ['./modal-nfe-xml.component.scss']
})
export class ModalNfeXmlComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfe: any;
  subscription: Subscription[] = [];
  xml;
  xmlSrc: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.ABRIR_XML && res.open) {
        this.nfe = res.data;
        this.loading = true;
        this.getXml();
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.resetFields();
    this.open = false;
    this.loading = false;
  }

  async getXml() {
    //TODO
    const chave = this.nfe?.chaveNfe ? this.nfe?.chaveNfe : this.nfe.chave;
    const response = await lastValueFrom(this.nfeService.getNfeXml(chave));

    if (response?.error) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Alerta',
        detail: 'Não foi possível obter o arquivo desta NF-e',
        key: 'global-toast',
        sticky: true
      });
    } else {
      this.xml = new XmlBeautufy().beautify(response, {
        indent: '  ',
        useSelfClosingElement: true
      });
      this.open = true;
    }
    this.loading = false;
  }

  resetFields() {
    this.xml = null;
    this.loading = false;
  }

  download() {
    const file = new Blob([this.xml], { type: 'text/xml' });
    this.xmlSrc = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = this.xmlSrc;
    a.target = '_blank';
    a.download = `${this.nfe.chaveNfe}.xml` ?? `${this.nfe.chaveNfe}.xml`;
    a.click();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
