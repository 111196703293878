<p-dialog
  #dialog
  maskStyleClass="background-modal"
  [contentStyle]="{ overflowY: overflowYHidden ? 'hidden' : 'auto' }"
  [blockScroll]="blockScroll"
  [draggable]="false"
  [modal]="true"
  [style]="{ width: width, height: height }"
  [maximizable]="true"
  [autoZIndex]="true"
  [appendTo]="appendTo"
  [baseZIndex]="9000"
  (onHide)="close($event)"
  [(visible)]="display"
  [appendTo]="appendTo"
  [closable]="closable"
  [closeOnEscape]="closeOnEscape"
  [transitionOptions]="'0ms'">
  <ng-template pTemplate="header">
    <section *ngIf="modalTitle; else elseBlock" class="container-header-modal">
      <span class="header-title">{{ modalTitle }}</span>
      <span *ngIf="nfeNumber" class="nfe-key-header-modal">
        {{ nfeNumber }}
      </span>
    </section>
    <ng-template #elseBlock>
      <section class="container-header-modal">
        <ng-content select="[header]"></ng-content>
      </section>
    </ng-template>
  </ng-template>
  <div
    *ngIf="hasLineHeader"
    [class.mb-0]="removeTitleMargin"
    class="header-line-bottom"></div>
  <ng-content></ng-content>
  <ng-template pTemplate="footer">
    <ng-content select="[footer]"></ng-content>
  </ng-template>
</p-dialog>
