import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { Select } from 'primeng/select';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { User } from 'src/app/core/models/entities/user/user';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';

import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription, lastValueFrom } from 'rxjs';

import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-master-selecao',
  template: `
    <app-modal
      [width]="'40vw'"
      height="300px"
      modalTitle="Alteração de Perfil de Acesso"
      (closeModal)="close()"
      [removeTitleMargin]="false"
      [display]="open">
      <section content class="p-fluid grid formgrid">
        <div class="input-group field col-12">
          <label for="master-select" class="">
            Selecione o usuário Master desejado:
          </label>
          <p-select
            appendTo="body"
            *ngIf="masterList?.length"
            [(ngModel)]="selectedMaster"
            id="master-select"
            placeholder="Selecione"
            [options]="masterList"
            optionLabel="nome"></p-select>
        </div>
      </section>
      <section footer class="container-footer-modal">
        <p-button
          (click)="close()"
          class=" btn-vermelho mr-2"
          icon="pi pi-times"
          label="Cancelar"></p-button>
        <p-button
          (click)="confirm()"
          [disabled]="!selectedMaster"
          class="btn-verde"
          icon="pi pi-check"
          label="Alterar"></p-button>
      </section>
    </app-modal>
  `,
  standalone: true,
  imports: [DropdownModule, FormsModule, ModalComponent, NgIf, Button, Select]
})
export class ModalMasterSelecaoComponent implements OnInit, OnDestroy {
  open: boolean;
  masterList: { id: number; nome: string }[] = [];
  selectedMaster: { id: number; nome: string };
  loggedUser: User;
  subscription: Subscription[] = [];
  private MODAL_CHANGE_USER_MASTER = 'MODAL_CHANGE_USER_MASTER';

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.TODOS &&
        res.open &&
        res.name === this.MODAL_CHANGE_USER_MASTER
      ) {
        this.getLoggedUser();
        this.open = true;
      }
    });

    this.subscription.push(subscription);
  }

  getLoggedUser() {
    this.loggedUser = this.authService.getUserValue();
    this.masterList = this.loggedUser.usuariosMaster;
  }

  close() {
    this.masterList = [];
    this.open = false;
    this.selectedMaster = undefined as any;
  }

  async confirm() {
    if (this.selectedMaster) {
      const response = await lastValueFrom(
        this.authService.changeMaster(this.selectedMaster?.id)
      );

      if (response) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Perfil alterado com sucesso!',
          key: 'global-toast',
          sticky: true
        });

        await this.router.navigate(['inicio']);
      }
    }
    this.close();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
