import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { jsPDF } from 'jspdf';
import { lastValueFrom } from 'rxjs';

import { EXPORT_OPTIONS } from '../../core/consts/EXPORT-OPTIONS';
import { ContingencyManagementResponse } from '../../core/models/responses/gestao-monitoramento/gestao-monitoramento-response';
import { ContingencyManagementService } from '../../core/services/contingency-management/contingency-management.service';

@Component({
  selector: 'app-contingency-management',
  templateUrl: './contingency-management.component.html',
  styleUrls: ['./contingency-management.component.scss']
})
export class ContingencyManagementComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  exportOptions = EXPORT_OPTIONS;
  statusFilter = [
    { text: 'Captando em Ambos', value: true },
    {
      text: 'Não Captando (Contingência ou SEFAZ)',
      value: false
    }
  ];

  dataSource: ContingencyManagementResponse[];

  constructor(private contingencyService: ContingencyManagementService) {}

  async ngOnInit() {
    this.getData();
  }

  export(event: any, filename: string) {
    switch (event?.itemData?.value) {
      case 1:
        this.exportPdf(filename);
        break;
      case 2:
        this.onExportingCSV(event, filename + '.csv');
        break;
      case 3:
        this.exportWorksheet(event, filename + '.xlsx');
        break;
      case 4:
        this.exportWorksheet(event, filename + '.xls');
        break;
    }
  }

  exportPdf(filename: string) {
    const doc = new jsPDF('landscape');
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGrid.instance
    }).then(() => {
      doc.save(filename + '.pdf');
    });
  }

  onExportingCSV(event, filename: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Nfes');
    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet: worksheet
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          filename
        );
      });
    });
    event.cancel = true;
  }

  exportWorksheet(event, filename: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Nfes');

    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          filename
        );
      });
    });
    event.cancel = true;
  }

  async getData() {
    this.dataSource = [];
    const response: any = await lastValueFrom(this.contingencyService.list());
    if (response?.dados) {
      this.dataSource = response.dados;
    }
  }
}
