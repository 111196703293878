import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { Company } from '../../../core/models/entities/company/company';
import { CompanyService } from '../../../core/services/company/company.service';

@Component({
  selector: 'app-modal-log-activation-company',
  templateUrl: './modal-log-activation-company.component.html',
  styleUrls: ['./modal-log-activation-company.component.scss']
})
export class ModalLogActivationCompanyComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Output() modalResponse = new EventEmitter();
  @Input() company: Company;
  @Input() open: boolean;
  title = 'Log de Ativação/Inativação';
  companyTitle: string;
  dataSource: any;

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.open) {
      this.companyTitle = this.company?.nomeFantasia;
      this.getLog();
    }
  }

  getLog() {
    this.companyService
      .getLog(this.company?.id)
      .subscribe(response => (this.dataSource = response.dados));
  }

  close() {
    this.modalResponse.emit({ open: false });
    this.company = undefined as any;
  }

  ngOnDestroy() {
    this.close();
  }
}
