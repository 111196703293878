import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SidebarFiltersConfig } from '../../models/interfaceObjects/sidebar-filters-config/sidebar-filters-config';

@Injectable({
  providedIn: 'root'
})
export class SidebarFiltersService {
  private subject: BehaviorSubject<SidebarFiltersConfig> =
    new BehaviorSubject<SidebarFiltersConfig>({});

  constructor() {}

  send(config?: SidebarFiltersConfig) {
    if (config) {
      this.subject.next(config);
    } else {
      this.subject.next({ send: true });
    }
  }

  reset() {
    this.subject.next({ reset: true });
  }

  close() {
    this.subject.next({ close: true });
  }

  receive(): Observable<SidebarFiltersConfig> {
    return this.subject;
  }
}
