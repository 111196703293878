import { StatusSefazEnum } from 'src/app/core/enums/status-sefaz.enum';

import { CommonModule } from '@angular/common';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appStatusSefaz]',
  standalone: true
})
export class StatusSefazDirective implements OnInit {
  @Input() status;
  constructor(private el: ElementRef) {}

  ngOnInit() {
    switch (this.status) {
      case StatusSefazEnum.OTIMO:
        this.el.nativeElement.style.color = '#2D78EF';
        break;
      case StatusSefazEnum.BOM:
        this.el.nativeElement.style.color = '#6BB82A';
        break;
      case StatusSefazEnum.LENTO:
        this.el.nativeElement.style.color = '#EFBB2F';
        break;
      case StatusSefazEnum.CRITICO:
        this.el.nativeElement.style.color = '#F85040';
        break;
    }
  }
}
