import { SharedModule } from 'primeng/api';
import { DrawerModule } from 'primeng/drawer';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  standalone: true,
  styleUrls: ['./drawer.component.scss'],
  imports: [CommonModule, SharedModule, DrawerModule]
})
export class DrawerComponent {
  @Input() visible: boolean;
  @Input() titleDrawer: string;
  @Output() closeDrawer = new EventEmitter();
  @Input() width = '50vw';

  constructor() {}

  close() {
    this.closeDrawer.emit({ visible: false });
  }
}
