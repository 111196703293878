<p-messages severity="info" class="mensagem-aviso">
  <ng-template pTemplate>
    <i class="pi pi-video aviso-tutorial"></i>
    <div class="ml-2">
      Confira neste tutorial, como você pode resolver o status de não captação
      de notas pela contingência.
    </div>
  </ng-template>
</p-messages>

<app-page-wrapping overflowX="hidden" pageTitle="GESTÃO DE CONTINGÊNCIA">
  <section class="overflow-x-hidden" content>
    <dx-data-grid
      #dataGrid
      class="grid-container"
      [hoverStateEnabled]="true"
      [dataSource]="dataSource"
      [remoteOperations]="false"
      [allowColumnReordering]="true"
      [rowAlternationEnabled]="true"
      [showBorders]="true">
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-scrolling rowRenderingMode="virtual"></dxo-scrolling>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-column-chooser
        sortOrder="asc"
        mode="select"
        title="Personalizar Colunas"
        [enabled]="true"></dxo-column-chooser>

      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-group-panel
        emptyPanelText="Arraste uma coluna para agrupar"
        [visible]="true"></dxo-group-panel>

      <dxo-toolbar>
        <dxi-item location="after" name="export"></dxi-item>
        <dxi-item location="after" name="searchPanel"></dxi-item>
        <dxi-item location="after" title="Exportar Dados" id="dropdown-export">
          <dx-drop-down-button
            [dropDownOptions]="{ width: 150 }"
            icon="download"
            displayExpr="name"
            [items]="exportOptions"
            (onItemClick)="
              export($event, 'confear-gestao-contingencia')
            "></dx-drop-down-button>
        </dxi-item>
        <dxi-item name="columnChooserButton" location="after"></dxi-item>

        <dxi-item location="before" name="groupPanel"></dxi-item>
      </dxo-toolbar>

      <dxi-column
        [allowSearch]="true"
        [minWidth]="120"
        alignment="right"
        dataField="cnpjDestinatario"
        dataType="number"
        caption="CNPJ DEST."></dxi-column>
      <dxi-column
        [allowSearch]="true"
        [minWidth]="100"
        alignment="center"
        dataField="segmento"
        dataType="string"
        caption="SEGMENTO">
        <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
      </dxi-column>
      <dxi-column
        [minWidth]="100"
        [groupIndex]="0"
        alignment="left"
        dataField="nomeFantasiaDestinatario"
        dataType="string"
        caption="EMPRESA"></dxi-column>
      <dxi-column
        [allowSearch]="true"
        [minWidth]="120"
        alignment="right"
        dataType="string"
        dataField="cnpjEmitente"
        caption="CNPJ EMITENTE">
        <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
      </dxi-column>
      <dxi-column
        [allowSearch]="true"
        [minWidth]="190"
        alignment="left"
        dataType="string"
        dataField="nomeFantasiaEmitente"
        caption="FORNECEDORES">
        <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
      </dxi-column>
      <dxi-column
        [minWidth]="80"
        alignment="center"
        dataField="ufDestinatario"
        dataType="string"
        caption="UF"></dxi-column>
      <dxi-column caption="DATA DA ÚLTIMA CAPTAÇÃO" alignment="center">
        <dxi-column
          [allowSearch]="true"
          [minWidth]="130"
          [format]="'dd/MM/yyyy HH:mm'"
          [allowFiltering]="false"
          alignment="center"
          dataType="date"
          dataField="dataUltCaptSefaz"
          caption="SEFAZ/MUNIÍPIO">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [allowSearch]="false"
          [minWidth]="130"
          [format]="'dd/MM/yyyy HH:mm'"
          [allowFiltering]="false"
          caption="E-MAIL"
          alignment="center"
          dataType="date"
          dataField="dataUltCaptEmail"></dxi-column>
      </dxi-column>
      <dxi-column
        [width]="100"
        filterType="exclude"
        [allowSearch]="false"
        alignment="center"
        caption="STATUS"
        dataField="status"
        cellTemplate="statusTemplate">
        <dxo-header-filter [dataSource]="statusFilter"></dxo-header-filter>
      </dxi-column>
      <dxi-column
        [minWidth]="320"
        dataType="email"
        alignment="left"
        dataField="email"
        caption="E-MAILS"></dxi-column>

      <div *dxTemplate="let data of 'statusTemplate'">
        <app-icon
          [id]="data?.data?.status.id"
          [icon]="data?.data?.status?.icone"
          [title]="data?.data?.status?.descricao"></app-icon>
      </div>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="false"
        [showInfo]="true"
        [showNavigationButtons]="true"></dxo-pager>
    </dx-data-grid>
  </section>
</app-page-wrapping>
