import { MenuTreeComponent } from '@shared/components/menu/components/menu-item/menu-tree.component';
import { TreeModule } from 'primeng/tree';
import { MenuItem } from 'src/app/core/models/entities/menu/menuItem';
import { MenuService } from 'src/app/core/services/menu/menu.service';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [CommonModule, TreeModule, MenuTreeComponent]
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('menu', { static: false }) menu;
  display: boolean;
  items: MenuItem[] | null;
  selected: any;
  subscription: Subscription[] = [];

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    const subscription = this.menuService.listenStorageChanges();
    this.listenDisplayMenu();
    this.listenMenuItems();

    this.subscription.push(subscription);
  }

  listenDisplayMenu() {
    const subscription = this.menuService
      .getDisplayMenuObservable()
      .subscribe((display: boolean) => {
        this.display = display;
      });

    this.subscription.push(subscription);
  }

  listenMenuItems() {
    const subscription = this.menuService
      .getItemsObservable()
      .subscribe(menuItems => {
        this.items = menuItems;
      });

    this.subscription.push(subscription);
  }

  toggleMenu = () => this.menuService[this.display ? 'close' : 'open']();

  ngOnDestroy() {
    this.subscription.forEach(subs => subs.unsubscribe());
  }
}
