<section class="container default-border-page">
  <i class="success-icon pi pi-check-circle"></i>
  <h3 class="title">Prontinho! Seus dados foram atualizados!</h3>
  <div class="mb-4">
    Agora o seu login é
    <span class="text-blue">{{ login }}</span>
  </div>

  <ng-container *ngIf="indIntegrador">
    <div class="text-orange mb-4">
      Identificamos que seu usuário realiza integração com seu software de PDV.
      No novo CONFEAR, você precisa realizar a instalação do plugin de
      integração.
    </div>
    <div class="text-orange mb-4">
      Para realizar a instalação, por favor, acesse o menu
      <span class="text-blue">Configurações -> Servidores Integração PDV.</span>
    </div>
    <div class="text-orange mb-4">
      Essa configuração é necessária para que o CONFEAR continue integrando as
      notas automaticamente em seu software de PDV.
    </div>
  </ng-container>
  <div class="text-red">
    Ao finalizar o recadastramento, sua sessão será encerrada e você deverá se
    autenticar utilizando seu novo login.
  </div>
</section>
<div class="row justify-content-center mt-4">
  <p-button class="btn-verde ml-2" (click)="next()">Finalizar</p-button>
</div>
