import { Produto } from 'src/app/core/models/entities/nfe-analise/produto';
import { ProdutoDetalhado } from 'src/app/core/models/entities/nfe-produto/produto-detalhado';
import { Nfe } from 'src/app/core/models/entities/nfe/nfe';
import { DefaultResponse } from 'src/app/core/models/responses/default-response';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';

import { CurrencyPipe, DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-nfe-analise-produto',
  templateUrl: './modal-nfe-analise-produto.component.html',
  standalone: true,
  imports: [ModalComponent, CurrencyPipe, DecimalPipe, DatePipe, NgIf],
  styleUrls: ['./modal-nfe-analise-produto.component.scss']
})
export class ModalNfeAnaliseProdutoComponent implements OnInit {
  open: boolean;
  nfe: Nfe;
  product: Produto;
  productDetailed: ProdutoDetalhado;
  subscription: Subscription[] = [];

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.open && res?.name === 'PRODUCT MODAL') {
        this.nfe = res.data.nfe;
        this.product = res.data.product;
        this.getProduct();
      }
    });

    this.subscription.push(subscription);
  }

  getProduct() {
    this.nfeService
      .productAnalysis(this.nfe.chaveNfe, this.product.codigo)
      .subscribe((response: DefaultResponse<ProdutoDetalhado>) => {
        this.productDetailed = response.dados;
        this.open = true;
      });
  }

  close() {
    this.open = false;
  }
}
