import { PageWrappingComponent } from '@shared/components/page-wrapping/page-wrapping.component';
import { DxDataGridModule, DxDropDownButtonModule } from 'devextreme-angular';
import { NgxMaskPipe } from 'ngx-mask';
import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { DefaultResponse } from 'src/app/core/models/responses/default-response';
import { RelatorioAvancadoProduto } from 'src/app/core/models/responses/relatorio-avancado-produto/relatorio-avancado-produto';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoadingService } from 'src/app/core/services/loading/loading.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';
import { ReportService } from 'src/app/core/services/report/report.service';

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { BaseGridComponent } from '../base-grid/base-grid';

@Component({
  selector: 'app-produto-grid-relatorio-avancado',
  templateUrl: './produto-grid-relatorio-avancado.component.html',
  standalone: true,
  styleUrls: ['./produto-grid-relatorio-avancado.component.scss'],
  imports: [
    CommonModule,
    PageWrappingComponent,
    DxDataGridModule,
    DxDropDownButtonModule,
    Button,
    NgxMaskPipe
  ]
})
export class ProdutoGridRelatorioAvancadoComponent
  extends BaseGridComponent<RelatorioAvancadoProduto>
  implements OnChanges, OnInit
{
  @Input() pageTitle: string;
  @Input() nfes;
  @Output() dataLoading = new EventEmitter<boolean>();
  @Input() getData: boolean;
  override data: DefaultResponse<RelatorioAvancadoProduto>;

  constructor(
    protected override nfeService: NfeService,
    protected override authService: AuthService,
    protected override modalService: ModalService,
    protected override messageService: MessageService,
    protected override loadingService: LoadingService,
    protected reportService: ReportService
  ) {
    super(
      nfeService,
      authService,
      modalService,
      messageService,
      loadingService
    );
  }

  async ngOnInit() {
    this.gridPermission = CONFEAR_PERMISSIONS.RELATORIO_AVANCADO_PRODUTO;
    await this.loadInitalConfigs();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.nfes?.length) {
      await this.getReport();
    }
  }

  async getReport() {
    const response = await lastValueFrom(
      this.reportService.getAdvancedByProduct(this.nfes)
    );
    if (response?.dados) {
      this.data = response;
      this.dataSource = response.dados;
    }
    this.dataLoading.emit(true);
  }
}
