<app-page-wrapping overflowX="hidden" [pageTitle]="pageTitle">
  <section content class="container-product-advanced-report">
    <dx-data-grid
      #dataGrid
      class="grid-container"
      id="grid-product-advanced-report"
      (onOptionChanged)="listenGridChanges($event)"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [dataSource]="dataSource"
      [remoteOperations]="false"
      [allowColumnReordering]="true"
      [rowAlternationEnabled]="true"
      [showBorders]="true">
      <dxo-search-panel
        [width]="240"
        [visible]="true"
        [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-group-panel
        emptyPanelText="Arraste uma coluna para agrupar"
        [visible]="true"></dxo-group-panel>
      <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

      <dxo-paging [pageSize]="150"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"></dxo-pager>
      <dxo-export [enabled]="true"></dxo-export>

      <dxo-column-chooser
        sortOrder="asc"
        mode="select"
        title="Personalizar Colunas"
        [enabled]="true"></dxo-column-chooser>

      <dxo-toolbar>
        <dxi-item location="after" name="searchPanel"></dxi-item>

        <dxi-item location="after" title="Exportar Dados" id="dropdown-export">
          <dx-drop-down-button
            [dropDownOptions]="{ width: 150 }"
            icon="download"
            displayExpr="name"
            [items]="exportOptions"
            (onItemClick)="
              export($event, 'confear-relatorio-avancado-produtos')
            "></dx-drop-down-button>
        </dxi-item>

        <dxi-item
          location="after"
          title="Escolha o tipo de grid"
          text="Editar grid"
          [visible]="true">
          <dx-drop-down-button
            [elementAttr]="{ id: 'btn-select-grid' }"
            icon="columnfield"
            displayExpr="name"
            keyExpr="value"
            class="btn-dropdown-grid"
            [width]="200"
            [useSelectMode]="true"
            [selectedItemKey]="selectedGridOption"
            [items]="gridOptions"
            (onItemClick)="selectGridOption($event)"></dx-drop-down-button>
        </dxi-item>

        <dxi-item location="after" name="save">
          <p-button
            [disabled]="disableSaveGrid"
            (click)="handleCustomGrid()"
            class="btn-save-grid btn-verde"
            title="Salvar Personalização da Grid"
            type="button"
            icon="pi pi-save"
            iconPos="left"></p-button>
        </dxi-item>

        <dxi-item location="after" name="delete">
          <p-button
            [disabled]="disableDeleteGrid"
            (click)="confirmResetGridConfig()"
            class="btn-delete-grid btn-vermelho"
            title="Excluir Personalização da Grid"
            type="button">
            <span class="material-icons-outlined">grid_off</span>
          </p-button>
        </dxi-item>

        <dxi-item name="columnChooserButton" location="after"></dxi-item>
        <dxi-item location="before" name="groupPanel"></dxi-item>
      </dxo-toolbar>

      <dxi-column
        [width]="80"
        alignment="left"
        caption="ITEM"
        dataField="item"
        cellTemplate="cellTemplateItem"></dxi-column>
      <div class="text-center" *dxTemplate="let data of 'cellTemplateItem'">
        {{ data.value }}
      </div>
      <dxi-column
        [minWidth]="270"
        alignment="left"
        caption="PRODUTO"
        dataField="produto"
        cellTemplate="cellTemplateProduto"></dxi-column>
      <div
        class="flex align-items-center"
        *dxTemplate="let data of 'cellTemplateProduto'">
        {{ data.value }}
      </div>
      <dxi-column
        [minWidth]="100"
        dataType="date"
        alignment="left"
        caption="DT. EMISSÃO"
        dataField="dataEmissao"></dxi-column>

      <dxi-column
        [minWidth]="190"
        alignment="left"
        dataType="string"
        dataField="destinatario"
        caption="DESTINATÁRIO"></dxi-column>
      <dxi-column
        [minWidth]="120"
        dataType="string"
        alignment="lfet"
        caption="CNPJ - DESTINATÁRIO"
        dataField="cnpjDestinatario"
        cellTemplate="cnpjDestinatarioTemplate"></dxi-column>
      <div *dxTemplate="let cell of 'cnpjDestinatarioTemplate'">
        {{ cell.value | mask: '00.000.000/0000-00' }}
      </div>

      <dxi-column
        [minWidth]="190"
        alignment="left"
        caption="EMITENTE"
        dataField="emitente"></dxi-column>

      <dxi-column
        [minWidth]="120"
        dataType="string"
        alignment="left"
        caption="CNPJ - EMITENTE"
        dataField="cnpjEmitente"
        cellTemplate="cnpjEmitenteTemplate"></dxi-column>
      <div *dxTemplate="let cell of 'cnpjEmitenteTemplate'">
        {{ cell.value | mask: '00.000.000/0000-00' }}
      </div>

      <dxi-column
        [minWidth]="80"
        alignment="center"
        caption="CFOP"
        dataField="cfop"></dxi-column>
      <dxi-column
        [minWidth]="90"
        alignment="center"
        caption="O/CST"
        dataField="ocst"></dxi-column>
      <dxi-column
        alignment="center"
        [minWidth]="90"
        caption="DESC. (%)"
        dataField="descontoPercentual"
        cellTemplate="cellTemplateDesconto"></dxi-column>
      <div *dxTemplate="let data of 'cellTemplateDesconto'">
        {{ data.value | percent: '1.2-2' }}
      </div>

      <dxi-column
        [width]="80"
        alignment="left"
        caption="NÚMERO NF-e"
        dataField="numeroNfe"
        cellTemplate="cellTemplatenumeroNfe"></dxi-column>
      <div
        class="text-center"
        *dxTemplate="let data of 'cellTemplatenumeroNfe'">
        {{ data.value }}
      </div>

      <dxi-column
        [width]="80"
        alignment="left"
        caption="CÓD. PRODUTO"
        dataField="codigo"
        cellTemplate="cellTemplateCodigo"></dxi-column>
      <div class="text-center" *dxTemplate="let data of 'cellTemplateCodigo'">
        {{ data.value }}
      </div>

      <dxi-column
        [minWidth]="100"
        alignment="right"
        caption="CUSTO UNITÁRIO"
        dataField="custoUnitario"
        dataType="number"
        [format]="formatCurrency"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="right"
        caption="CUSTO UNITÁRIO FINAL"
        dataField="custoUnitarioFinal"
        dataType="number"
        [format]="formatCurrency"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="right"
        caption="DESCONTO (R$)"
        dataField="descontovalor"
        dataType="number"
        [format]="formatCurrency"
        cellTemplate="cellTemplateDescontoValor"></dxi-column>
      <div
        class="text-red"
        *dxTemplate="let data of 'cellTemplateDescontoValor'">
        {{ data.value | currency }}
      </div>

      <dxi-column
        [minWidth]="70"
        alignment="right"
        caption="ST (-)"
        dataField="st"
        dataType="number"
        [format]="formatCurrency"></dxi-column>

      <dxi-column
        [minWidth]="70"
        alignment="right"
        caption="FCP (+)"
        dataField="fcp"
        dataType="number"
        [format]="formatCurrency"></dxi-column>

      <dxi-column
        [minWidth]="70"
        alignment="right"
        caption="IPI (+)"
        dataField="ipi"
        dataType="number"
        [format]="formatCurrency"></dxi-column>

      <dxi-column
        [width]="'130px'"
        alignment="right"
        caption="OUTRAS DESPESAS (+)"
        dataType="number"
        [format]="formatCurrency"
        dataField="outrasDespesas"></dxi-column>
      <dxi-column
        [width]="'100px'"
        alignment="right"
        caption="FRETE (+)"
        dataType="number"
        [format]="formatCurrency"
        dataField="frete"></dxi-column>
      <dxi-column
        [minWidth]="150"
        alignment="right"
        dataType="number"
        [format]="formatCurrency"
        caption="CUSTO TOTAL DOS PRODUTOS"
        dataField="custoTotalProdutos"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="QTD. COMPRADA"
        dataField="qtdComprada"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="NCM"
        dataField="ncm"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="CEST"
        dataField="cest"></dxi-column>

      <dxi-column
        [minWidth]="150"
        alignment="center"
        caption="EAN"
        dataField="ean"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS Desonerado (Valor)"
        dataType="number"
        [visible]="false"
        [format]="formatCurrency"
        dataField="valorIcmsDesonerado"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        [visible]="false"
        caption="ICMS Desonerado (Motivo)"
        dataField="motDesoneracaoDesc"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        [visible]="false"
        caption="ICMS Deduz Desoneração?"
        dataField="deduzDesoneracao"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS (Alíquota)"
        [visible]="false"
        dataField="aliquota"
        cellTemplate="cellTemplateAliquotaICMS"></dxi-column>
      <div *dxTemplate="let data of 'cellTemplateAliquotaICMS'">
        {{ data.value / 100 | percent: '1.2-2' }}
      </div>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS (Valor)"
        dataType="number"
        [visible]="false"
        [format]="formatCurrency"
        dataField="valorIcms"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS ST (Alíquota)"
        dataType="number"
        [visible]="false"
        dataField="aliquotaImpostoICMSST"
        cellTemplate="cellTemplateAliquotaICMSST"></dxi-column>
      <div *dxTemplate="let data of 'cellTemplateAliquotaICMSST'">
        {{ data.value / 100 | percent: '1.2-2' }}
      </div>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS ST (Valor)"
        dataType="number"
        [visible]="false"
        [format]="formatCurrency"
        dataField="valorIcmsSt"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS BC Retido (Valor)"
        dataType="number"
        [visible]="false"
        [format]="formatCurrency"
        dataField="valorBcIcmsRetido"></dxi-column>

      <dxi-column
        [minWidth]="100"
        alignment="center"
        caption="ICMS ST Retido (Valor)"
        dataType="number"
        [visible]="false"
        [format]="formatCurrency"
        dataField="valorIcmsStRetido"></dxi-column>

      <dxo-summary>
        <dxi-total-item
          displayFormat="{0}"
          column="custoTotalProdutos"
          summaryType="sum"
          [valueFormat]="formatCurrency"></dxi-total-item>

        <dxi-total-item
          displayFormat="{0}"
          column="fcp"
          summaryType="sum"
          [valueFormat]="formatCurrency"></dxi-total-item>

        <dxi-total-item
          displayFormat="{0}"
          column="ipi"
          summaryType="sum"
          [valueFormat]="formatCurrency"></dxi-total-item>

        <dxi-total-item
          displayFormat="{0}"
          column="outrasDespesas"
          summaryType="sum"
          [valueFormat]="formatCurrency"></dxi-total-item>

        <dxi-total-item
          displayFormat="{0}"
          column="st"
          summaryType="sum"
          [valueFormat]="formatCurrency"></dxi-total-item>

        <dxi-total-item
          displayFormat="{0}"
          column="descontoValor"
          summaryType="sum"
          [valueFormat]="formatCurrency"></dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </section>
</app-page-wrapping>
