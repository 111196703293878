import { EventoManifestacao } from 'src/app/core/models/entities/nfe/evento-manifestacao';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { IconeComponent } from '../../icone/icone.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-legenda-grid',
  template: `
    <app-modal
      modalTitle="Visualizando Legenda"
      (closeModal)="close()"
      [display]="open"
      width="40vw">
      <div class="container-content-modal-legend">
        <div class="header-content-modal-legend">
          <app-icone
            fontSize="23px"
            class="icon-modal-legend"
            [icon]="legend?.icone"></app-icone>
          <h4 class="description-legend">{{ legend?.descricao }}</h4>
        </div>
        <div class="content-modal">{{ legend?.descricaoDetalhada }}</div>
      </div>
    </app-modal>
  `,
  standalone: true,
  imports: [ModalComponent, IconeComponent],
  styleUrls: ['./modal-grid-legend.component.scss']
})
export class ModalGridLegendComponent implements OnInit, OnDestroy {
  open: boolean;
  legend: EventoManifestacao | null;
  subscription: Subscription[] = [];

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res?.name === 'MODAL LEGEND' && res.open) {
        this.legend = res.data?.legend;
        this.open = true;
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.open = false;
    this.legend = null;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
