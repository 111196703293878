import { Icon } from 'src/app/core/models/entities/icon/icon';

import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icone',
  template: `
    <section class="container-icon" [style.justifyContent]="justifyContent">
      <em
        *ngIf="icon"
        [style.fontSize]="fontSize"
        [style.margin]="margin"
        [style.marginTop]="marginTop"
        [style.marginLeft]="marginLeft"
        [style.marginRight]="marginRight"
        [style.marginBottom]="marginBottom"
        [style.color]="icon.cor"
        [class]="[icon.class]">
        {{ icon.lib === 'material' ? icon.nome : '' }}
      </em>
    </section>
  `,
  styleUrls: ['./icone.component.scss'],
  standalone: true,

  imports: [NgIf]
})
export class IconeComponent {
  @Input() icon: Icon | undefined;
  @Input() margin: string;
  @Input() marginTop: string;
  @Input() marginLeft: string;
  @Input() marginBottom: string;
  @Input() marginRight: string;
  @Input() justifyContent: string;
  @Input() fontSize: string;

  constructor() {}
}
