import { SafePipe } from '@shared/pipes/safe/safe.pipe';
import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Subscription, lastValueFrom, of } from 'rxjs';

import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-nfe-impressao',
  templateUrl: './modal-nfe-impressao.component.html',
  standalone: true,
  imports: [ModalComponent, LoadingFragmentComponent, SafePipe, Button],
  styleUrls: ['./modal-nfe-impressao.component.scss']
})
export class ModalNfeImpressaoComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfe: any;
  subscription: Subscription[] = [];
  pdfSrcDownload: any;
  secureUrlPdf: SafeResourceUrl;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.ABRIR_NFE_IMPRESSAO && res.open) {
        this.nfe = res.data;
        this.pdfSrcDownload = null;
        this.loading = true;
        this.getNfe();
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.loading = false;
    this.pdfSrcDownload = null;
    this.open = false;
  }

  download() {
    const a = document.createElement('a');
    a.href = this.pdfSrcDownload;
    a.target = '_blank';
    a.download = `${this.nfe.chaveNfe}.pdf` ?? `${this.nfe.chaveNfe}.pdf`;
    a.click();
  }

  async getNfe() {
    //TODO a api precisa manter a integridade das entidades(NFE)
    const chave = this.nfe?.chaveNfe ? this.nfe?.chaveNfe : this.nfe.chave;
    const response = await lastValueFrom(this.nfeService.getNfePdf(chave));

    if (response?.error) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Alerta',
        detail: 'Não foi possível obter o arquivo desta NF-e',
        key: 'global-toast',
        sticky: true
      });
    } else {
      this.open = true;
      const file = new Blob([response], { type: 'application/pdf' });
      this.pdfSrcDownload = URL.createObjectURL(file);
      this.secureUrlPdf = await lastValueFrom(of(this.blobToDataURL(response)));
    }
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  blobToDataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = _e => resolve(reader.result as string);
      reader.onerror = _e => reject(reader.error);
      reader.onabort = _e => reject(new Error('Read aborted'));
      reader.readAsDataURL(blob);
    });
  }
}
