import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DefaultResponse } from '../../models/responses/default-response';
import { ContingencyManagementResponse } from '../../models/responses/gestao-monitoramento/gestao-monitoramento-response';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class ContingencyManagementService {
  private url = `${environment.baseUrl}/fornecedores/gestao-contingencia`;

  constructor(private http: HttpClient) {}

  list(): Observable<DefaultResponse<ContingencyManagementResponse>> {
    return this.http
      .get<DefaultResponse<ContingencyManagementResponse>>(`${this.url}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
