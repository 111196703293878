import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
  standalone: true
})
export class TimePipe implements PipeTransform {
  transform(value: number): string {
    const totalInMin = value * 60;
    let hour = 0;
    let day = 0;
    hour = Math.floor(totalInMin / 60);
    day = Math.floor(totalInMin / 1440);

    if (day) {
      return `${day} dias`;
    }

    if (hour) {
      return `${hour}h`;
    }

    return `${totalInMin} min`;
  }
}
