<app-modal
  modalTitle="Manifestação de NF-e"
  (closeModal)="close()"
  [closable]="false"
  [display]="open">
  <section class="container-modal-manifest-confirmation">
    <form
      class="content-modal-manifest-confirmation"
      (submit)="confirm()"
      [formGroup]="form">
      <span class="material-icons-outlined icon-info mb-2">info</span>
      <div class="mb-3">
        Você realmente deseja realizar a manifestação da(s) nota(s) fiscal(ais)
        abaixo, com o tipo de evento
        <span class="text-blue">
          {{ data?.eventoManifestacao?.descricao }}?
        </span>
      </div>
      <span class="text-blue word-break mb-3">{{ nfes }}</span>
      <div
        class="container-textarea mb-3"
        [formGroup]="form"
        (submit)="confirm()">
        <ng-container
          *ngIf="data?.eventoManifestacao?.id === OPERACAO_NAO_REALIZADA">
          <span class="label-justificativa mb-2">
            Justificativa da manifestação
          </span>
          <textarea
            formControlName="justificativa"
            class="w-full"
            [rows]="7"
            pTextarea></textarea>
        </ng-container>
      </div>
      <section class="container-buttons">
        <p-button
          type="button"
          (click)="close()"
          label="Não"
          class="btn-vermelho mr-2"
          icon="pi pi-times"></p-button>
        <p-button
          type="submit"
          label="Sim"
          class="btn-verde"
          icon="pi pi-check"></p-button>
      </section>
    </form>
  </section>
</app-modal>
