<app-modal
  [modalTitle]="title"
  width="50vw"
  (closeModal)="close()"
  [display]="open">
  <app-loading-fragment [loading]="loading"></app-loading-fragment>
  <section *ngIf="open" class="container-modal-register-company">
    <form class="p-fluid grid formgrid" [formGroup]="form">
      <div class="col-12 flex justify-content-end mb-1">
        <span class="required-symbol">*</span>
        <span class="required-info">Campo(s) de preenchimento obrigatório</span>
      </div>
      <div class="section-title col-12 mb-2">
        <span>Informações da Empresa</span>
        <div class="hr"></div>
      </div>
      <div class="input-group field lg:col-4 md:col-6 col-12">
        <label for="cnpj">
          CNPJ
          <span class="required-symbol">*</span>
        </label>
        <p-inputMask
          (focusout)="getCompanyInfo()"
          id="cnpj"
          [autoClear]="false"
          [showClear]="true"
          [unmask]="true"
          formControlName="cnpj"
          placeholder="99.999.999/9999-99"
          mask="99.999.999/9999-99"></p-inputMask>
      </div>
      <div class="input-group field lg:col-4 md:col-6 col-12">
        <label for="nomeFantasia">
          Nome Fantasia
          <span class="required-symbol">*</span>
        </label>
        <input
          formControlName="nomeFantasia"
          id="nomeFantasia"
          type="text"
          pInputText />
      </div>
      <div class="input-group field lg:col-4 md:col-6 col-12">
        <label for="cnpjMatriz">CNPJ da Matriz</label>
        <p-inputMask
          [unmask]="true"
          [autoClear]="false"
          [showClear]="true"
          formControlName="cnpjMatriz"
          id="cnpjMatriz"
          type="text"
          mask="99.999.999/9999-99"></p-inputMask>
      </div>
      <div class="input-group field lg:col-8 md:col-6 col-12">
        <label for="razaoSocial">
          Razão Social
          <span class="required-symbol">*</span>
        </label>
        <input
          formControlName="razaoSocial"
          id="razaoSocial"
          type="text"
          pInputText />
      </div>
      <div class="input-group field lg:col-4 md:col-6 col-12">
        <label for="telefoneComercial">
          Telefone Comercial
          <span class="required-symbol">*</span>
        </label>
        <p-inputMask
          [autoClear]="false"
          [showClear]="true"
          [unmask]="true"
          slotChar=" "
          mask="(99) 999999999"
          placeholder="(00) 999999999"
          id="telefoneComercial"
          formControlName="telefoneComercial"></p-inputMask>
      </div>
      <ng-container [formGroup]="enderecoGroup">
        <div class="input-group field lg:col-3 md:col-6 col-12">
          <label for="cep">
            CEP
            <span class="required-symbol">*</span>
          </label>
          <p-inputMask
            [unmask]="true"
            [autoClear]="false"
            [showClear]="true"
            mask="99999-999"
            id="cep"
            formControlName="cep"></p-inputMask>
        </div>

        <div class="input-group field lg:col-3 md:col-6 col-12">
          <label for="logradouro">
            Logradouro
            <span class="required-symbol">*</span>
          </label>
          <input
            id="logradouro"
            formControlName="logradouro"
            type="text"
            pInputText />
        </div>

        <div class="input-group field lg:col-3 md:col-6 col-12">
          <label for="numero">Número</label>
          <input id="numero" formControlName="numero" type="text" pInputText />
        </div>
        <div class="input-group field lg:col-3 md:col-6 col-12">
          <label for="complemento">Complemento</label>
          <input
            id="complemento"
            formControlName="complemento"
            type="text"
            pInputText />
        </div>

        <div class="input-group field lg:col-4 md:col-6 col-12">
          <label for="bairro">
            Bairro
            <span class="required-symbol">*</span>
          </label>
          <input id="bairro" formControlName="bairro" type="text" pInputText />
        </div>

        <div class="input-group field lg:col-4 md:col-6 col-12">
          <label for="cidade">
            Cidade
            <span class="required-symbol">*</span>
          </label>
          <input id="cidade" formControlName="cidade" type="text" pInputText />
        </div>

        <div class="input-group field lg:col-4 md:col-6 col-12">
          <label for="uf">
            UF
            <span class="required-symbol">*</span>
          </label>
          <input id="uf" formControlName="uf" type="text" pInputText />
        </div>
      </ng-container>

      <div class="input-group field lg:col-6 md:col-6 col-12">
        <label for="inscricaoEstadual">Inscrição Estadual</label>
        <p-inputMask
          [unmask]="true"
          [autoClear]="false"
          [showClear]="true"
          id="inscricaoEstadual"
          mask="99999999999999"
          formControlName="inscricaoEstadual"></p-inputMask>
      </div>

      <div class="input-group field lg:col-6 md:col-6 col-12">
        <label for="softwarePdv">Software de PDV</label>
        <p-dropdown
          placeholder="Selecione"
          optionLabel="descricao"
          id="softwarePdv"
          [options]="softwares"
          formControlName="softwarePdv"></p-dropdown>
      </div>
      <div class="section-title mb-3 mt-2 col-12">
        <span>Informações do responsável pela empresa</span>
        <div class="hr"></div>
      </div>

      <div
        class="input-group field lg:col-6 md:col-6 col-12"
        *ngIf="loggedUser.indAdministradorSistema">
        <label for="usuarioMasterEmpresa">
          Usuário Master
          <span class="required-symbol">*</span>
        </label>
        <p-dropdown
          id="usuarioMasterEmpresa"
          [options]="usuariosSistema"
          formControlName="idUsuarioMaster"
          optionLabel="nome"
          optionValue="id"
          [filter]="true"
          filterBy="nomeLogin"
          [showClear]="true"
          placeholder="Selecione um Usuário">
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
              <div>{{ selectedOption.nome }}</div>
            </div>
          </ng-template>
          <ng-template let-usuariosSistema pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ usuariosSistema.nomeLogin }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <ng-container [formGroup]="responsavelGroup">
        <div class="input-group field col-12">
          <label for="nomeResponsavel">
            Nome do Responsável
            <span class="required-symbol">*</span>
          </label>
          <input
            id="nomeResponsavel"
            formControlName="nome"
            type="text"
            pInputText />
        </div>
        <div class="input-group field lg:col-6 md:col-6 col-12">
          <label for="email">
            E-mail
            <span class="required-symbol">*</span>
          </label>
          <input id="email" formControlName="email" type="text" pInputText />
        </div>
        <div class="input-group field lg:col-6 md:col-6 col-12">
          <label for="celular">
            Celular
            <span class="required-symbol">*</span>
          </label>
          <p-inputMask
            [autoClear]="false"
            [showClear]="true"
            [unmask]="true"
            mask="(99) 99999-9999"
            placeholder="(00) 99999-9999"
            id="celular"
            formControlName="celular"></p-inputMask>
        </div>
      </ng-container>
    </form>
  </section>
  <form
    [formGroup]="form"
    (ngSubmit)="register()"
    class="container-footer-modal"
    footer>
    <button
      pButton
      title="Cancelar"
      (click)="close()"
      class="btn-dark-red"
      type="button"
      label="Cancelar"
      icon="pi pi-times"></button>
    <button
      pButton
      title="Adicionar empresa"
      class="btn-green mr-2"
      type="submit"
      [label]="company ? 'Editar empresa' : 'Adicionar empresa'"
      icon="pi pi-check"></button>
  </form>
</app-modal>
