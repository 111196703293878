import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChangePasswordComponent } from '../../core/components/change-password/change-password.component';
import { AuthGuard } from '../../core/guards/auth/auth.guard';
import { ChangePasswordGuard } from '../../core/guards/change-password/change-password.guard';
import { InitialSetupGuard } from '../../core/guards/initial-setup/initial-setup.guard';
import { LoginGuard } from '../../core/guards/login/login.guard';
import { environment } from '../../environments/environment';
import { ContingencyManagementComponent } from '../contingency-management/contingency-management.component';
import { HomeComponent } from '../home/home.component';

function getTitle(title) {
  if (!environment.production) {
    console.log('homologa');
    return `Homologa - ${title}`;
  }
  return `CONFEAR - ${title}`;
}

const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoginGuard],
    title: getTitle('login'),
    loadChildren: () =>
      import('core/components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'esqueci-minha-senha',
    canActivate: [LoginGuard],
    title: getTitle('Esqueci minha senha'),
    loadChildren: () =>
      import('core/components/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      )
  },
  {
    path: 'alterar-senha-logado',
    title: getTitle('Alteração Senha (Logado)'),
    component: ChangePasswordComponent
  },
  {
    path: 'inicio',
    component: HomeComponent,
    canActivate: [AuthGuard],
    title: getTitle('Início')
  },
  {
    path: 'monitoramento',
    canActivate: [AuthGuard],
    title: getTitle('Monitoramento de NF-e'),
    loadChildren: () =>
      import('../nfe-management/nfe-management.module').then(
        m => m.NfeManagementModule
      )
  },
  {
    path: 'alertas',
    canActivate: [AuthGuard],
    title: getTitle('Alertas'),
    loadChildren: () =>
      import('../alert-list/alert-list.module').then(m => m.AlertListModule)
  },
  {
    path: 'manifestacao',
    title: getTitle('Manifestação de NF-e'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../nfe-manifest/nfe-manifest.module').then(
        m => m.NfeManifestModule
      )
  },
  {
    path: 'biblioteca',
    title: getTitle('Biblioteca de NF-e'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../nfe-library/nfe-library.module').then(m => m.NfeLibraryModule)
  },
  {
    path: 'gerenciar-empresas',
    title: getTitle('Cadastro de Empresas'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../company-list/company-list.module').then(
        m => m.CompanyListModule
      )
  },
  {
    path: 'gerenciar-etiquetas',
    title: getTitle('Gestão de Etiquetas'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../tag-management/tag-management.module').then(
        m => m.TagManagementModule
      )
  },
  {
    path: 'gerenciar-alertas',
    title: getTitle('Gestão de Alertas'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../alert-management/alert-management.module').then(
        m => m.AlertManagementModule
      )
  },
  {
    path: 'gerenciar-usuarios',
    title: getTitle('Gestão de Usuários'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../user-management/user-management.module').then(
        m => m.UserManagementModule
      )
  },
  {
    path: 'gerenciar-grupo-usuario',
    title: getTitle('Gestão de Grupo de Usuários'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../user-group-management/user-group-management.module').then(
        m => m.UserGroupManagementModule
      )
  },
  {
    path: 'relatorio-consolidado',
    title: getTitle('Relatório Consolidado'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../consolidated-report-management/consolidated-report-management.module'
      ).then(m => m.ConsolidatedReportManagementModule)
  },
  {
    path: 'certificado-digital',
    title: getTitle('Gerenciamento de Certificado Digital'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../digital-certificate-management/digital-certificate-management.module'
      ).then(m => m.DigitalCertificateManagementModule)
  },
  {
    path: 'analise-mercado',
    title: getTitle('Análise de Mercado'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../market-analysis/market-analysis.module').then(
        m => m.MarketAnalysisModule
      )
  },
  {
    path: 'comprar-modulo',
    title: getTitle('Comprar Módulo'),
    loadChildren: () =>
      import('core/components/buy-module/buy-module.module').then(
        m => m.BuyModuleModule
      )
  },
  {
    path: 'sem-acesso',
    title: getTitle('Sem Acesso'),
    loadChildren: () =>
      import('core/components/route-denied/route-denied.module').then(
        m => m.RouteDeniedModule
      )
  },
  {
    path: 'acesso-indisponivel',
    title: getTitle('Acesso indisponível'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        'core/components/unavailable-access/unavailable-access.module'
      ).then(m => m.UnavailableAccessModule)
  },
  {
    path: 'alterar-senha',
    title: getTitle('Alteração Senha (Deslogado)'),
    component: ChangePasswordComponent
  },
  {
    path: 'alterar-dados-usuario',
    title: getTitle('Altera Dados Cadastrais'),
    loadChildren: () =>
      import('core/components/edit-user-data/edit-user-data.module').then(
        m => m.EditUserDataModule
      )
  },
  {
    path: 'condicao-comercial',
    canActivate: [AuthGuard],
    title: getTitle('Condição Comercial'),
    loadChildren: () =>
      import('../commercial-condition/commercial-condition.module').then(
        m => m.CommercialConditionModule
      )
  },
  {
    path: 'pagina-inicial-setup',
    canActivate: [InitialSetupGuard],
    loadChildren: () =>
      import(
        'core/components/initial-setup/welcome-page-setup/welcome-page-setup.module'
      ).then(m => m.WelcomePageSetupModule)
  },
  {
    path: 'pagina-conclusao-setup',
    canActivate: [InitialSetupGuard],
    loadChildren: () =>
      import(
        'core/components/initial-setup/finished-page-setup/finished-page-setup.module'
      ).then(m => m.FinishedPageSetupModule)
  },
  {
    path: 'passo-a-passo-setup',
    canActivate: [InitialSetupGuard],
    loadChildren: () =>
      import('core/components/initial-setup/initial-setup.module').then(
        m => m.InitialSetupModule
      )
  },
  {
    path: 'gerenciar-pdv',
    title: getTitle('Gerenciamento de Gestão de Servidores'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../pdv-integration-management/pdv-integration-management.module'
      ).then(m => m.PdvIntegrationManagementModule)
  },
  {
    path: 'gerenciar-fornecedores',
    title: getTitle('Gerenciamento de Fornecedores'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../supplier-management/supplier-management.module').then(
        m => m.SupplierManagementModule
      )
  },
  {
    path: 'contas-a-pagar',
    title: getTitle('Financeiro'),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../financial/bills-to-pay/bills-to-pay.module').then(
        m => m.BillsToPayModule
      )
  },
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  {
    path: 'gerenciar-contingencia',
    component: ContingencyManagementComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../contingency-management/contingency-management.module').then(
        m => m.ContingencyManagementModule
      )
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
