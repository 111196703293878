import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-template-login',
  templateUrl: './template-login.component.html',
  standalone: true,
  imports: [RouterLink, CommonModule],
  styleUrls: ['./template-login.component.scss']
})
export class TemplateLoginComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() link = '';
  @Input() backButtonLink = '';

  constructor() {}
}
