import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class SefazService {
  private url = `${environment.baseUrl}/sefaz`;

  constructor(private http: HttpClient) {}

  getStatus(chaveNfe: string, cnpjDestinatario: string): Observable<any> {
    return this.http
      .get<any>(`${this.url}/${chaveNfe}/situacao?cnpj=${cnpjDestinatario}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
