import { MenuItem } from '@core/models/entities/menu/menuItem';
import { IOMenuItem } from '@core/models/interfaceObjects/menu/IOMenuItem';
import { AuthService } from '@core/services/auth/auth.service';
import { MenuService } from '@core/services/menu/menu.service';
import { TreeModule } from 'primeng/tree';

import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-tree',
  templateUrl: './menu-tree.component.html',
  styleUrls: ['./menu-tree.component.scss'],
  standalone: true,
  imports: [CommonModule, TreeModule]
})
export class MenuTreeComponent implements OnInit, OnChanges {
  @Input() data: MenuItem[] | null;
  voDataTree: IOMenuItem[] = [];
  currentItem: IOMenuItem | null;
  lastExpanded: IOMenuItem | null;
  readonly isLogout = 'logout';

  constructor(
    private router: Router,
    private menuService: MenuService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.listenCurrentMenuItem();
  }

  listenCurrentMenuItem() {
    this.menuService.getCurrentMenuItemObservable().subscribe(currentItem => {
      if (currentItem) {
        this.setMenuItem(currentItem);
        this.expandRecursiveTree(currentItem);
      }
    });
  }

  convertTreeData() {
    this.voDataTree = [];
    this.data?.forEach((menuItem: MenuItem) => {
      this.voDataTree.push(IOMenuItem.buildTree(menuItem, null));
    });
  }

  private expandRecursiveTree(item: IOMenuItem) {
    let current: IOMenuItem | null = item;
    let parent: IOMenuItem | null = null;
    while (!IOMenuItem.parentKeyAbsent(current)) {
      if (!current?.parentId) {
        break;
      }
      parent = IOMenuItem.searchItem(this.voDataTree, current?.parentId);

      if (parent && parent.children.length > 0) {
        parent.expanded = true;
      }

      current = parent;
    }
  }

  expandItem(item: IOMenuItem) {
    if (this.lastExpanded && this.lastExpanded !== item && item.root) {
      this.lastExpanded.expanded = false;
    }

    if (item.root) {
      this.lastExpanded = item;
    }

    item.expanded = !item.expanded;
  }

  setMenuItem(currentItem: IOMenuItem) {
    if (currentItem.children.length > 0) {
      return;
    }
    const previous = this.currentItem;
    const isEquals = previous && previous === currentItem;

    if (!isEquals && previous) {
      this.unmarkActiveItem(previous);
      previous.actived = false;
      previous.expanded = false;
    }

    if (currentItem?.children.length > 0) {
      this.expandItem(currentItem);
    }

    if (currentItem?.children.length === 0) {
      currentItem.actived = true;
      this.markActiveItem(currentItem);
      this.menuService.setCurrentMenuItem(currentItem);
    }

    this.currentItem = currentItem;
    if (currentItem.url === this.isLogout) {
      this.authService.logout().subscribe();
    } else if (currentItem.url.includes('http')) {
      window.open(currentItem.url, '_blank');
    } else {
      this.router.navigate([currentItem.url]);
    }
  }

  markActiveItem(currentItem: IOMenuItem) {
    let found = IOMenuItem.searchItem(this.voDataTree, currentItem.id);
    if (found) {
      found.actived = true;
    }
  }

  unmarkActiveItem(item: IOMenuItem) {
    let found = IOMenuItem.searchItem(this.voDataTree, item.id);
    if (found) {
      found.actived = false;
    }
  }

  ngOnChanges() {
    if (this.data) {
      this.convertTreeData();
    }
  }
}
