import { MessageService } from 'primeng/api';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  catchError,
  debounceTime,
  delay,
  filter,
  of,
  switchMap,
  take,
  throwError
} from 'rxjs';

import { User } from '../../models/entities/user/user';
import { AuthService } from '../../services/auth/auth.service';
import { LoadingService } from '../../services/loading/loading.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) {}

  private refreshTokenInProgress = false;
  private logoutInProgress = false;
  private urlsWithoutToken = ['usuario/login', 'refreshToken'];
  private reqHasToken: boolean;
  private user: User;
  private refreshTokenSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const abortController = new AbortController();

    this.user = this.authService.getUserValue();
    this.reqHasToken = !this.urlsWithoutToken.some(url =>
      request.url.includes(url)
    );

    if (this.reqHasToken) {
      if (User.isRefreshTokenExpired(this.user)) {
        const cloned = request.clone({
          signal: abortController.signal
        } as any);
        abortController.abort('Token Expired');

        return this.addTokenToHeader(cloned, next).pipe(
          catchError(err => {
            if (abortController.signal.aborted) {
              return this.authService
                .logout({ refreshtokenExpired: true })
                .pipe(debounceTime(1000));
            }
            return throwError(err);
          })
        );
      }

      if (User.isTokenExpired(this.user)) {
        return this.refreshToken(request, next);
      }

      return this.addTokenToHeader(request, next).pipe(
        catchError(err => {
          if (err.status === 409 && !this.logoutInProgress) {
            this.logoutInProgress = true;
            return this.authService.logout({ sessionDuplicated: true }).pipe(
              debounceTime(500),
              switchMap(response => {
                this.showError(err?.error?.mensagem);
                this.logoutInProgress = false;
                return of(response);
              })
            );
          }
          return throwError(err);
        })
      );
    }

    return next.handle(request);
  }

  showError(message: string[]) {
    const detail = message?.reduce((acc: string, cc: string) => {
      return acc.concat(cc);
    });

    this.messageService.add({
      severity: 'warn',
      summary: 'Alerta',
      key: 'global-toast',
      sticky: true,
      detail
    });
  }

  refreshToken(request, next): Observable<any> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(false);
      return this.authService.refreshToken(this.user).pipe(
        debounceTime(500),
        switchMap((user: User) => {
          this.user = user;
          this.authService.setSession(user);
          this.refreshTokenSubject.next(true);
          this.refreshTokenInProgress = false;
          this.loadingService.close();
          this.messageService.clear();
          return this.addTokenToHeader(request, next);
        }),
        catchError(async err => {
          const user = this.user;
          user.refreshTokenExpired = true;
          this.refreshTokenInProgress = false;
          this.authService.setSession(user);
          this.authService.exit();
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(completed => completed),
        take(1),
        switchMap(completed => {
          return this.addTokenToHeader(request, next);
        })
      );
    }
  }

  addTokenToHeader(request, next) {
    const { apiKey, empresas } = this.user;

    let empresasHeader = '';
    empresasHeader = empresas?.reduce((acc, empresa, index) => {
      return index === 0 || index === empresas.length
        ? acc.concat(`${empresa.id}`)
        : acc.concat(`,${empresa.id}`);
    }, empresasHeader);

    const authReq = request.clone({
      headers: request.headers
        .set('Authorization', `${apiKey}`)
        .set('idEmpresas', `${empresasHeader}`)
    });

    return next.handle(authReq);
  }
}
