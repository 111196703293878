import { AuthGuard } from '@core/guards/auth/auth.guard';
import { InitialSetupGuard } from '@core/guards/initial-setup/initial-setup.guard';
import { LoginGuard } from '@core/guards/login/login.guard';

import { Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { InicioComponent } from './pages/inicio/inicio.component';
import { SenhaAlteracaoComponent } from './pages/senha-alteracao/senha-alteracao.component';

function getTitle(title) {
  if (!environment.production) {
    return `Homologa - ${title}`;
  }
  return `CONFEAR - ${title}`;
}

export const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoginGuard],
    title: getTitle('login'),
    loadComponent: () =>
      import('./pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'esqueci-minha-senha',
    canActivate: [LoginGuard],
    title: getTitle('Esqueci minha senha'),
    loadComponent: () =>
      import('./pages/senha-resgate/senha-resgate.component').then(
        m => m.SenhaResgateComponent
      )
  },
  {
    path: 'alterar-senha-logado',
    title: getTitle('Alteração Senha (Logado)'),
    component: SenhaAlteracaoComponent
  },
  {
    path: 'inicio',
    component: InicioComponent,
    canActivate: [AuthGuard],
    title: getTitle('Início')
  },
  {
    path: 'monitoramento',
    canActivate: [AuthGuard],
    title: getTitle('Monitoramento de NF-e'),
    loadComponent: () =>
      import('./pages/nfe/monitoramento/monitoramento.component').then(
        m => m.MonitoramentoComponent
      )
  },
  {
    path: 'alertas',
    canActivate: [AuthGuard],
    title: getTitle('Alertas'),
    loadComponent: () =>
      import('./pages/alerta/lista/alerta.component').then(
        m => m.AlertaComponent
      )
  },
  {
    path: 'gerenciar-alertas',
    title: getTitle('Gestão de Alertas'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/alerta/gestao/alerta-gestao.component').then(
        m => m.AlertaGestaoComponent
      )
  },
  {
    path: 'gestao-elertas',
    canActivate: [AuthGuard],
    title: getTitle('Alertas'),
    loadComponent: () =>
      import('./pages/alerta/lista/alerta.component').then(
        m => m.AlertaComponent
      )
  },
  {
    path: 'manifestacao',
    title: getTitle('Manifestação de NF-e'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/nfe/manifestacao/nfe-manifestacao.component').then(
        m => m.NfeManifestacaoComponent
      )
  },
  {
    path: 'biblioteca',
    title: getTitle('Biblioteca de NF-e'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/nfe/biblioteca/nfe-biblioteca.component').then(
        m => m.NfeBibliotecaComponent
      )
  },
  {
    path: 'gerenciar-empresas',
    title: getTitle('Cadastro de Empresas'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/empresa/empresa.component').then(m => m.EmpresaComponent)
  },
  {
    path: 'gerenciar-etiquetas',
    title: getTitle('Gestão de Etiquetas'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/etiqueta/etiqueta.component').then(
        m => m.EtiquetaComponent
      )
  },
  {
    path: 'gerenciar-alerta',
    title: getTitle('Gestão de Alerta'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/alerta/gestao/alerta-gestao.component').then(
        m => m.AlertaGestaoComponent
      )
  },
  {
    path: 'gerenciar-usuarios',
    title: getTitle('Gestão de Usuários'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/usuario/gestao/usuario.component').then(
        m => m.UsuarioComponent
      )
  },
  {
    path: 'gerenciar-grupo-usuario',
    title: getTitle('Gestão de Grupo de Usuários'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        './pages/usuario/grupo/usuario-grupo-gestao/usuario-grupo-gestao.component'
      ).then(m => m.UsuarioGrupoGestaoComponent)
  },
  {
    path: 'relatorio-consolidado',
    title: getTitle('Relatório Consolidado'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        './pages/relatorio-consolidado/relatorio-consolidado.component'
      ).then(m => m.RelatorioConsolidadoComponent)
  },
  {
    path: 'certificado-digital',
    title: getTitle('Gerenciamento de Certificado Digital'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/certificado-digital/certificado-digital.component').then(
        m => m.CertificadoDigitalComponent
      )
  },
  {
    path: 'analise-mercado',
    title: getTitle('Análise de Mercado'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/analise-mercado/analise-mercado.component').then(
        m => m.AnaliseMercadoComponent
      )
  },
  {
    path: 'comprar-modulo',
    title: getTitle('Comprar Módulo'),
    loadComponent: () =>
      import('./pages/modulo-compra/modulo-compra.component').then(
        m => m.ModuloCompraComponent
      )
  },
  {
    path: 'sem-acesso',
    title: getTitle('Sem Acesso'),
    loadComponent: () =>
      import('./pages/acesso/negado/acesso-negado.component').then(
        m => m.AcessoNegadoComponent
      )
  },
  {
    path: 'indisponivel',
    title: getTitle('Acesso indisponível'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/acesso/indisponivel/acesso-indisponivel.component').then(
        m => m.AcessoIndisponivelComponent
      )
  },
  {
    path: 'alterar-senha',
    title: getTitle('Alteração Senha (Deslogado)'),
    component: SenhaAlteracaoComponent
  },
  {
    path: 'alterar-dados-usuario',
    title: getTitle('Altera Dados Cadastrais'),
    loadComponent: () =>
      import('./pages/usuario/edit-user-data/edit-user-data.component').then(
        m => m.EditUserDataComponent
      )
  },
  {
    path: 'faturas',
    canActivate: [AuthGuard],
    title: getTitle('Minhas Faturas'),
    loadComponent: () =>
      import('./pages/faturas/faturas.component').then(m => m.FaturasComponent)
  },
  {
    path: 'condicao-comercial',
    canActivate: [AuthGuard],
    title: getTitle('Condição Comercial'),
    loadComponent: () =>
      import('./pages/condicao-comercial/condicao-comercial.component').then(
        m => m.CondicaoComercialComponent
      )
  },
  {
    path: 'pagina-inicial-setup',
    canActivate: [InitialSetupGuard],
    loadComponent: () =>
      import('./pages/setup/inicio/setup-inicio.component').then(
        m => m.SetupInicioComponent
      )
  },
  {
    path: 'pagina-conclusao-setup',
    canActivate: [InitialSetupGuard],
    loadComponent: () =>
      import('./pages/setup/conclusao/setup-conclusao.component').then(
        m => m.SetupConclusaoComponent
      )
  },
  {
    path: 'passo-a-passo-setup',
    canActivate: [InitialSetupGuard],
    loadComponent: () =>
      import('./pages/setup/passo-a-passo/setup-passo-a-passo.component').then(
        m => m.SetupPassoAPassoComponent
      )
  },
  {
    path: 'gerenciar-pdv',
    title: getTitle('Gerenciamento de Gestão de Servidores'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/pdv-integracao/pdv-integracao.component').then(
        m => m.PdvIntegracaoComponent
      )
  },
  {
    path: 'gerenciar-fornecedores',
    title: getTitle('Gerenciamento de Fornecedores'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/fornecedor/fornecedor.component').then(
        m => m.FornecedorComponent
      )
  },
  {
    path: 'contas-a-pagar',
    title: getTitle('Financeiro'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/financeiro/contas-a-pagar/contas-a-pagar.component').then(
        m => m.ContasAPagarComponent
      )
  },
  {
    path: 'gerenciar-contingencia',
    title: getTitle('Gestão de Contingência'),
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        './pages/contingency-management/contingency-management.component'
      ).then(m => m.ContingencyManagementComponent)
  },
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  {
    path: '**',
    component: InicioComponent,
    canActivate: [AuthGuard]
  }
];
