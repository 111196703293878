import { Dialog, DialogModule } from 'primeng/dialog';

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <p-dialog
      #dialog
      maskStyleClass="background-modal"
      [contentStyle]="{ overflowY: overflowYHidden ? 'hidden' : 'auto' }"
      [blockScroll]="blockScroll"
      [draggable]="false"
      [modal]="true"
      [style]="{ width: width, height: height }"
      [maximizable]="true"
      [autoZIndex]="true"
      [appendTo]="appendTo"
      [baseZIndex]="9000"
      (onHide)="close($event)"
      [(visible)]="display"
      [closable]="closable"
      [closeOnEscape]="closeOnEscape"
      [transitionOptions]="'0ms'">
      <ng-template pTemplate="header">
        <section
          *ngIf="modalTitle; else elseBlock"
          class="container-header-modal">
          <span class="header-title">{{ modalTitle }}</span>
          <span *ngIf="nfeNumber" class="nfe-key-header-modal">
            {{ nfeNumber }}
          </span>
        </section>
        <ng-template #elseBlock>
          <section class="container-header-modal">
            <ng-content select="[header]"></ng-content>
          </section>
        </ng-template>
      </ng-template>
      <div
        *ngIf="hasLineHeader"
        [class.mb-0]="removeTitleMargin"
        class="header-line-bottom"></div>
      <ng-content></ng-content>
      <ng-template pTemplate="footer">
        <ng-content select="[footer]"></ng-content>
      </ng-template>
    </p-dialog>
  `,
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [CommonModule, DialogModule]
})
export class ModalComponent implements OnChanges {
  @ViewChild(Dialog, { static: false }) dialog: Dialog;
  @Input() display: boolean;
  @Input() modalTitle: string;
  @Input() nfeNumber;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() width = '60vw';
  @Input() height = '';
  @Input() hasLineHeader = true;
  @Input() blockScroll = false;
  @Input() overflowYHidden = false;
  @Input() closable = true;
  @Input() closeOnEscape = true;
  @Input() removeTitleMargin = true;
  @Input() modalFull: boolean;
  @Input() appendTo = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.modalFull) {
      this.maximize();
    }
  }

  maximize() {
    this.dialog?.maximize();
  }

  close(event: any) {
    this.closeModal.emit(false);
  }
}
