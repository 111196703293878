import { Pipe, PipeTransform } from '@angular/core';

import { diffInDays } from '../../utils/date-utils';

@Pipe({
  name: 'daysAgo'
})
export class DaysAgoPipe implements PipeTransform {
  transform(date: string | undefined, ...args: unknown[]): unknown {
    if (date) {
      const today = new Date();
      const diff = Math.abs(diffInDays(today, new Date(date)));

      if (diff) {
        return diff === 1
          ? `Sem captação há ${diff} dia`
          : `Sem captação há ${diff} dias`;
      }

      return 'Captado Hoje';
    }
    return '';
  }
}
