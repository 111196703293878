import { ModalComponent } from '@shared/components/modal/modal.component';

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-modal-inicio-info',
  templateUrl: './modal-inicio-info.component.html',
  styleUrls: ['./modal-inicio-info.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalComponent]
})
export class ModalInicioInfoComponent implements OnInit, OnDestroy {
  @Input() open: boolean;
  @Output() modalClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.modalClose.emit(true);
  }

  ngOnDestroy() {
    this.close();
  }
}
