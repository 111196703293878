import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { Nfe } from 'src/app/core/models/entities/nfe/nfe';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';

import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-manifestacao-visualizacao-justificativa',
  template: `
    <app-modal (closeModal)="close()" [display]="open">
      <section header>
        Justificativa de Manifestação da NF-e
        <span class="nfe-key-header-modal">{{ nfe?.numeroNfe }}</span>
      </section>
      <app-loading-fragment
        height="340px"
        [loading]="loading"></app-loading-fragment>
      <ng-container *ngIf="!errorMessage">
        <section class="content-icon-message">
          <div class="content">
            <span class="material-icons-outlined icon">error</span>
            <span>{{ justificativaManifestacao }}</span>
          </div>
        </section>
      </ng-container>
      <div *ngIf="errorMessage">
        <span>{{ errorMessage }}</span>
      </div>
    </app-modal>
  `,
  standalone: true,
  imports: [ModalComponent, LoadingFragmentComponent, NgIf]
})
export class ModalManifestacaoVisualizacaoJustificativaComponent
  implements OnInit, OnDestroy
{
  open: boolean;
  loading: boolean;
  nfe: Nfe;
  subscription: Subscription[] = [];
  errorMessage: string;
  justificativaManifestacao: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res?.id === CONFEAR_PERMISSIONS.VISUALIZAR_JUSTIFICATIVA_CORRECAO &&
        res.open
      ) {
        this.nfe = res.data;
        if (this.nfe.chaveNfe != null) {
          this.loading = true;
          this.open = true;
        }
        this.getJustification();
      }
    });

    this.subscription.push(subscription);
  }

  getJustification() {
    this.nfeService
      .getJustification(this.nfe.chaveNfe)
      .subscribe((response: any) => {
        if (response?.dados) {
          this.justificativaManifestacao =
            response.dados?.justificativaManifestacao;
        }
        if (response?.error) {
          this.errorMessage = response.error?.mensagem?.reduce((acc, cc) => {
            this.errorMessage.concat(cc);
          });
        }
        this.loading = false;
      });
  }

  close() {
    this.errorMessage = '';
    this.open = false;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
