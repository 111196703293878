import { DxCheckBoxModule } from 'devextreme-angular';
import { MessageService } from 'primeng/api';
import { Button, ButtonDirective } from 'primeng/button';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { Etiqueta } from 'src/app/core/models/entities/etiqueta/etiqueta';
import { NfeTag } from 'src/app/core/models/entities/nfe-tag/nfe-tag';
import { Nfe } from 'src/app/core/models/entities/nfe/nfe';
import { EtiquetaService } from 'src/app/core/services/etiqueta/etiqueta.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';

import { NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Subscription, lastValueFrom } from 'rxjs';

import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-etiqueta-selecao',
  template: `
    <app-modal
      [removeTitleMargin]="false"
      (closeModal)="close()"
      [display]="open"
      [width]="'60vw'">
      <section header>Etiquetas</section>
      <app-loading-fragment
        height="200px"
        [loading]="loading"></app-loading-fragment>
      <section class="grid grid-no-gutter" *ngIf="open">
        <div class="col-12 lg:col-6" *ngFor="let tag of form">
          <div class="cell">
            <dx-check-box
              (onValueChanged)="listenCheckbox($event, tag)"
              [iconSize]="24"
              [(ngModel)]="tag.value"
              [enableThreeStateBehavior]="true"></dx-check-box>
            <span class="ml-2">{{ tag.descricao }}</span>
          </div>
        </div>
      </section>
      <section class="container-footer-modal" footer>
        <p-button
          title="Cancelar"
          (click)="close()"
          class="btn-vermelho mr-2"
          type="button"
          label="Cancelar"
          icon="pi pi-times"></p-button>
        <p-button
          (click)="save()"
          title="Salvar etiquetas"
          class="btn-verde mr-2"
          type="button"
          label="Salvar"
          icon="pi pi-check"></p-button>
      </section>
    </app-modal>
  `,
  standalone: true,
  imports: [
    ModalComponent,
    LoadingFragmentComponent,
    DxCheckBoxModule,
    FormsModule,
    ButtonDirective,
    NgIf,
    NgForOf,
    Button
  ],
  styleUrls: ['./modal-etiqueta-selecao.component.scss']
})
export class ModalEtiquetaSelecaoComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfes: Nfe[] = [];
  subscription: Subscription[] = [];
  tagDataResponse: NfeTag[];
  tagMap = new Map();
  tagList: Etiqueta[];
  form: any;
  jsonToSend: any;

  constructor(
    private modalService: ModalService,
    private etiquetaService: EtiquetaService,
    private nfeService: NfeService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService
      .listenChanges()
      .subscribe(async res => {
        if (res.id === CONFEAR_PERMISSIONS.ETIQUETAS && res.open) {
          this.open = true;
          this.loading = true;
          if (res?.data.hasOwnProperty('nfes')) {
            this.nfes = res.data?.nfes;
          }
          if (!res?.data.hasOwnProperty('nfes')) {
            this.nfes.push(res?.data);
          }

          await this.listTags();
          await this.getSelectedNfesTag();
          this.buildTagMap();
          this.filterTags();
          this.buildForm();
          this.loading = false;
        }
      });

    this.subscription.push(subscription);
  }

  async listTags() {
    const response = await lastValueFrom(this.etiquetaService.list(false));

    if (response?.dados) {
      this.tagList = response.dados;
    }
  }

  buildForm() {
    this.form = this.tagList.map(tag => {
      const value = this.getCheckboxState(tag);
      return {
        id: tag.id,
        descricao: tag.descricao,
        value
      };
    });
  }

  async getSelectedNfesTag() {
    const nfes = this.nfes.map(nfe => {
      return nfe.id;
    });
    const response = await lastValueFrom(
      this.nfeService.getNfeTags({ nfes: nfes })
    );

    if (response?.dados) {
      this.tagDataResponse = response?.dados;
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  clear() {
    this.tagMap = new Map();
    this.tagList = [];
    this.tagDataResponse = undefined as any;
    this.nfes = [];
    this.jsonToSend = undefined;
    this.form = undefined;
  }

  filterTags() {
    this.tagDataResponse.forEach(data => {
      data?.etiquetas.forEach(tag => {
        const tagValue = this.tagMap.get(tag.id);
        if (tagValue) {
          tagValue.count++;
        }
      });
    });
  }

  buildTagMap() {
    this.tagList.forEach(tag => {
      this.tagMap.set(tag.id, {
        descricao: tag.descricao,
        id: tag.id,
        count: 0
      });
    });
  }

  close() {
    this.open = false;
    this.clear();
  }

  getCheckboxState(tag: Etiqueta) {
    const tagObj = this.tagMap.get(tag.id);
    const tagCount = tagObj?.count;

    let value: any = false;

    if (tagCount > 0 && tagCount < this.nfes.length) {
      value = null;
    }

    if (tagCount === this.nfes.length) {
      value = true;
    }

    return value;
  }

  buildJsonToSend() {
    this.jsonToSend = this.tagDataResponse.map(data => {
      return {
        id: data.id,
        etiquetas: data.etiquetas.map(tag => {
          return tag.id;
        })
      };
    });
  }

  transformJson() {
    this.form.forEach(tag => {
      if (tag.value === true) {
        this.jsonToSend.forEach(nfe => {
          const hasTag = nfe.etiquetas?.find(id => {
            return tag.id === id;
          });

          if (!hasTag) {
            nfe.etiquetas.push(tag.id);
          }
        });
      } else if (tag.value === false) {
        this.jsonToSend.forEach(nfe => {
          const hasTag = nfe.etiquetas?.find(id => {
            return tag.id === id;
          });
          if (hasTag) {
            nfe.etiquetas = nfe.etiquetas?.filter(id => id !== tag.id);
          }
        });
      }
    });
  }

  async save() {
    this.buildJsonToSend();
    this.transformJson();
    const response = await lastValueFrom(
      this.nfeService.saveEtiquetas({ nfes: this.jsonToSend })
    );

    if (response?.dados) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        key: 'global-toast',
        sticky: true,
        detail: response?.dados?.mensagem
      });
      this.modalService.answer({
        id: CONFEAR_PERMISSIONS.ETIQUETAS,
        name: 'TAG_MODAL_ANSWER',
        data: {
          success: true
        }
      });
    }
    this.close();
    this.clear();
  }

  listenCheckbox(event: any, tag) {
    if (event.value === null || event.value === undefined) {
      tag.value = true;
    }
  }
}
