<app-page-wrapping overflowX="hidden" [pageTitle]="pageTitle">
  <section buttons>
    <div class="flex mb-2">
      <div
        class="container-info font-bold mr-2"
        *ngIf="!loggedUser.indAdministradorSistema">
        <span>LICENÇAS ADQUIRIDAS / DISPONÍVEIS:</span>
        <span class="count-license ml-1">
          {{ licencas?.licencasAdquiridas }} /
          {{ licencas?.licencasDisponiveis }}
        </span>
      </div>
      <div class="container-info font-bold warning text-red">
        <span class="pi pi-info-circle mr-2"></span>
        <span>
          As empresas marcadas em vermelho estão com o cadastro incompleto
        </span>
      </div>
    </div>
  </section>
  <section class="overflow-x-hidden" content>
    <section class="container-home">
      <dx-data-grid
        #dataGrid
        class="grid-container"
        id="gridContainer"
        (onRowPrepared)="addRowClass($event)"
        [hoverStateEnabled]="true"
        [columnMinWidth]="100"
        [allowColumnResizing]="true"
        [dataSource]="dataSource"
        [remoteOperations]="false"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [showBorders]="true">
        <dxo-search-panel
          [width]="240"
          [visible]="true"
          [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-group-panel
          emptyPanelText="Arraste uma coluna para agrupar"
          [visible]="true"></dxo-group-panel>
        <dxo-toolbar>
          <dxi-item>
            <section
              *ngIf="loggedUser.indAdministradorSistema"
              (click)="toggleSidebarFilter()"
              class="container-button-filters-grid"
              title="Abre os filtros avançados">
              <i class="pi pi-filter-slash"></i>
              <span class="select-all-rows">Filtros</span>
            </section>
          </dxi-item>
          <dxi-item name="searchPanel" location="after"></dxi-item>
          <dxi-item name="groupPanel"></dxi-item>
        </dxo-toolbar>

        <dxi-column
          dataType="string"
          alignment="center"
          caption="EMPRESA"
          [minWidth]="130"
          dataField="count"
          cellTemplate="empresaTemplate">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <div
          class="text-center"
          *dxTemplate="let data of 'empresaTemplate'"
          [title]="
            'Em operação desde ' +
            (data?.data?.dataIntegracaoCertificado | date: 'dd/MM/yyyy HH:mm')
          ">
          {{ data?.value }}
        </div>
        <dxi-column
          [minWidth]="130"
          dataType="string"
          alignment="center"
          caption="CNPJ"
          [calculateCellValue]="cnpjTransform"
          dataField="cnpj">
          <dxo-header-filter [allowSearch]="false"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [visible]="false"
          dataType="string"
          alignment="center"
          caption="CNPJ COM MÁSCARA"
          dataField="cnpjMask">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [minWidth]="130"
          dataType="string"
          alignment="left"
          caption="NOME FANTASIA"
          dataField="nomeFantasia">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [minWidth]="130"
          *ngIf="loggedUser?.indAdministradorSistema"
          dataType="string"
          alignment="left"
          caption="REDE"
          dataField="nomeFantasiaRede">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [minWidth]="80"
          *ngIf="loggedUser?.indAdministradorSistema"
          dataType="string"
          alignment="left"
          caption="CORPORATIVO"
          dataField="indCorporativo"
          cellTemplate="corporativoTemplate">
          <dxo-header-filter
            [dataSource]="filterCorporativo"
            [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <div
          class="container-active"
          *dxTemplate="let cell of 'corporativoTemplate'">
          <span>
            {{ cell?.data?.indCorporativo ? 'Sim' : 'Não' }}
          </span>
        </div>
        <dxi-column
          [minWidth]="130"
          *ngIf="loggedUser?.indAdministradorSistema"
          dataType="string"
          alignment="left"
          caption="MASTER"
          dataField="master.login">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [minWidth]="130"
          dataType="string"
          alignment="left"
          caption="RESPONSÁVEL"
          dataField="reponsavel"
          cellTemplate="responsavelTemplate">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <div *dxTemplate="let row of 'responsavelTemplate'">
          {{ row?.data?.responsavel?.nome }}
        </div>
        <dxi-column
          [minWidth]="130"
          dataType="string"
          alignment="center"
          caption="TELEFONE"
          dataField="telefone"
          cellTemplate="telefoneTemplate"></dxi-column>
        <div *dxTemplate="let row of 'telefoneTemplate'">
          {{ row?.data?.responsavel?.telefone | mask: '(00) 00000-0000' }}
        </div>
        <dxi-column
          [minWidth]="130"
          dataType="string"
          alignment="center"
          dataField="indAtivo"
          caption="ATIVO / INATIVO"
          cellTemplate="activeTemplate">
          <dxo-header-filter
            [dataSource]="filterStatusCompany"></dxo-header-filter>
        </dxi-column>
        <div
          class="container-active"
          [class.container-btn-active]="cell?.data?.indAtivo"
          [class.container-btn-inactive]="!cell?.data?.indAtivo"
          *dxTemplate="let cell of 'activeTemplate'">
          <span>{{ cell?.data?.indAtivo ? 'Ativa' : 'Inativa' }}</span>
          <button
            (click)="openModalChangeStatus(cell?.data)"
            [disabled]="!loggedUser?.indAdministradorSistema"
            pButton
            class="btn-thumbs"
            [icon]="
              cell?.data?.indAtivo ? 'pi pi-thumbs-up' : 'pi pi-thumbs-down'
            "
            [title]="
              cell?.data?.indAtivo
                ? ''
                : 'Empresa inativa. Verifique se já integrou o certificado digital da empresa.'
            "></button>
        </div>
        <dxi-column
          [minWidth]="220"
          [fixed]="true"
          type="buttons"
          alignment="center"
          caption="AÇÕES"
          cellTemplate="actionsTemplate"></dxi-column>
        <div *dxTemplate="let cell of 'actionsTemplate'">
          <button
            pButton
            title="Editar empresa"
            (click)="openModalEdit(cell?.data)"
            class="btn-edit mr-1"
            icon="pi pi-pencil"
            label="editar"></button>
          <button
            pButton
            title="Excluir empresa"
            (click)="openModalDelete(cell?.data)"
            class="btn-delete mr-1"
            icon="pi pi-trash"
            label="excluir"></button>
          <button
            *ngIf="loggedUser?.indAdministradorSistema"
            (click)="openModalDownload(cell?.data)"
            pButton
            title="Solicitar download de todas as notas"
            class="btn-backup-nfe"
            icon="pi pi-download"></button>
          <button
            *ngIf="loggedUser?.indAdministradorSistema"
            (click)="openModalLog(cell?.data)"
            pButton
            title="Logs de ativação/inativação"
            class="btn-log-activation">
            <span class="material-icons-outlined">history</span>
          </button>
        </div>

        <dxo-summary>
          <dxi-total-item
            displayFormat="TOTAL DE EMPRESAS: {0}"
            column="cnpj"
            summaryType="count"></dxi-total-item>
        </dxo-summary>
      </dx-data-grid>
    </section>
    <section class="container-action-buttons mt-3">
      <button
        pButton
        title="Cadastrar nova empresa"
        (click)="openModalRegister()"
        class="btn-green mb-2"
        type="button"
        label="Cadastrar nova empresa"
        icon="pi pi-building"></button>
      <button
        id="btn-download-empresas"
        (click)="download()"
        pButton
        title="Download da lista de empresas"
        class="btn-dark-blue ml-2 mb-2"
        type="button"
        label="Download da lista de empresas"
        icon="pi pi-download"></button>
    </section>
  </section>
</app-page-wrapping>

<app-modal-delete-company
  [company]="selectedCompany"
  [open]="showModalDelete"
  (modalResponse)="listenModalDelete($event)"></app-modal-delete-company>

<app-modal-active-entity
  (resModal)="listenModalEnableDisable($event)"
  [open]="showModalChangeStatus"
  [entity]="selectedCompany"
  [reasons]="reasonsModalAtivate"
  [modalTitle]="modalTitle"
  [textInfo]="textInfo"></app-modal-active-entity>

<app-modal-register-company
  [softwares]="softwaresPdv"
  (responseModal)="listenModalRegister($event)"
  [company]="selectedCompany"
  [open]="showModalRegister"></app-modal-register-company>

<app-modal-log-activation-entity
  [open]="showModalLog"
  [listLog]="listLogModal"
  [textInfo]="infoModalLog"
  (modalResponse)="listenModalLog($event)"></app-modal-log-activation-entity>

<app-sidebar
  *ngIf="loggedUser?.indAdministradorSistema"
  (closeSidebar)="listenSidebar($event)"
  [titleSidebar]="'Filtro Avançado'"
  [visible]="filterVisible">
  <app-company-filters
    (filters)="listenFilterValues($event)"
    content></app-company-filters>

  <div class="col-12 flex justify-content-start p-1" footer>
    <button
      (click)="send()"
      pButton
      id="btn-pesquisar"
      label="Pesquisar"
      class="btn-dark-blue ml-2 mr-2"
      type="button"
      title="Pesquisar"
      icon="pi pi-search"
      iconPos="left"></button>
    <button
      (click)="reset()"
      pButton
      id="btn-limpar"
      class="btn-dark-purple mr-2"
      type="button"
      title="Limpar Filtros"
      icon="pi pi-filter-slash"
      iconPos="left"></button>
  </div>
</app-sidebar>

<app-modal
  [width]="'25vw'"
  [display]="showModalDownload"
  [closable]="false"
  modalTitle="Solicitar Download">
  <section class="container-modal-grid-confirmation">
    <span class="mb-4">
      Você tem certeza que deseja prosseguir e solicitar o download de todas as
      notas desta empresa {{ selectedCompany?.razaoSocial }}?
    </span>
  </section>
  <section footer class="container-buttons">
    <button
      pButton
      type="button"
      (click)="close()"
      label="Não"
      class="btn-cancel"
      icon="pi pi-times"></button>
    <button
      pButton
      type="button"
      (click)="downloadBackupNfes(selectedCompany)"
      label="Sim"
      class="btn-confirm"
      icon="pi pi-check"></button>
  </section>
</app-modal>
