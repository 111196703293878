import { Button } from 'primeng/button';

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-legenda-custo',
  templateUrl: './modal-legenda-custo.component.html',
  styleUrls: ['./modal-legenda-custo.component.scss'],
  imports: [ModalComponent, Button],
  standalone: true
})
export class ModalLegendaCustoComponent {
  @Input() open: boolean;
  @Output() closeModal = new EventEmitter();
  constructor() {}

  close() {
    this.closeModal.emit({ close: true });
  }
}
