import { UserFilter } from '@core/models/entities/user/user-filter';
import { MessageService } from 'primeng/api';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { CompanyDisableReason } from '../../models/entities/company/company-disable-reason';
import { Grupo } from '../../models/entities/user/grupo';
import { User } from '../../models/entities/user/user';
import { UserAuth } from '../../models/entities/user/userAuth';
import { UsuarioSistema } from '../../models/entities/user/usuario-sistema';
import { LocalStorage } from '../../models/interfaceObjects/local-storage/local-storage';
import { UserGridConfigRequest } from '../../models/interfaceObjects/user-grid-config/user-grid-config-request';
import { GerenciamentoLicencaModuloRequest } from '../../models/requests/gerenciamento-licenca-modulo-request';
import { VincularEmpresaUsuarioRequest } from '../../models/requests/vincular-empresa-usuario-request';
import { DefaultResponse } from '../../models/responses/default-response';
import { GerenciamentoLicencaModulo } from '../../models/responses/gerenciamento-licenca-modulo/gerenciamento-licenca-modulo';
import { ValidarChaveSenhaResponse } from '../../models/responses/validar-chave-senha/validar-chave-senha-response';
import { handleResponseError } from '../../utils/handleResponseError';
import { LoadingService } from '../loading/loading.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { MenuService } from '../menu/menu.service';
import { Faturas } from '../../models/entities/user/faturas';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly baseUrl = `${environment.baseUrl}/usuario`;
  private loggedUserSubject: BehaviorSubject<User | undefined> =
    new BehaviorSubject<User | undefined>(undefined);
  private isLoggedIn = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private menuService: MenuService
  ) {
    this.listenLocalStorage();
  }

  listenLocalStorage() {
    this.localStorageService
      .getObservable()
      .subscribe((storage: LocalStorage | null) => {
        const user = storage?.user;
        this.isLoggedIn = !!user;
        this.loggedUserSubject.next(user);
      });
  }

  login(userAuth: UserAuth) {
    const { login, senha, tokenRecaptcha } = userAuth;
    return this.httpClient
      .post<User>(
        `${this.baseUrl}/login`,
        {
          login,
          senha,
          tokenRecaptcha
        },
        { headers: this.httpOptions.headers }
      )
      .pipe(
        map((user: User) => {
          this.loggedUserSubject.next(user);
          this.setTimeToken(user);
          // user.tempoTokenMin = User.tokenTimeLeft(user);
          this.setSession(user);
          setTimeout(() => {
            this.menuService.open();
          }, 2000);
          return user;
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  setTimeToken(user: User) {
    user.tempoTokenMin = User.tokenTimeLeft(user);
  }

  refreshToken(user: User): Observable<User> {
    const { apiKey, empresas } = this.getUserValue();
    let empresasHeader = '';
    empresasHeader = empresas?.reduce((acc, empresa, index) => {
      return index === 0 || index === empresas.length
        ? acc.concat(`${empresa.id}`)
        : acc.concat(`,${empresa.id}`);
    }, empresasHeader);
    return this.httpClient.post<User>(
      `${this.baseUrl}/refreshToken`,
      {
        login: user.login,
        refreshToken: user.refreshToken
      },
      {
        headers: {
          Authorization: apiKey,
          idEmpresas: empresasHeader
        }
      }
    );
  }

  setSession(authResult: User) {
    this.localStorageService.init();
    const { menu } = authResult;
    this.localStorageService.updateProperty('user', authResult);
    this.localStorageService.updateProperty('menu', menu);
    this.loggedUserSubject.next(authResult);
    this.isLoggedIn = true;
  }

  closeAndClear() {
    this.loadingService.close();
    this.messageService.clear('global-toast');
    this.menuService.close();
  }

  exit() {
    this.isLoggedIn = false;
    this.localStorageService.clear();
  }

  logout(config?: {
    sessionDuplicated?: boolean;
    refreshtokenExpired?: boolean;
  }): Observable<void> {
    return this.httpClient.post<any>(`${this.baseUrl}/logout`, {}).pipe(
      map(response => {
        this.closeAndClear();
        const user = this.getUserValue();
        if (config?.refreshtokenExpired) {
          user.refreshTokenExpired = true;
          this.loggedUserSubject.next(user);
        } else if (config?.sessionDuplicated) {
          user.sessionDuplicated = true;
          this.loggedUserSubject.next(user);
        } else {
          this.localStorageService.clear();
          this.loggedUserSubject.next(undefined);
        }
        this.isLoggedIn = false;
        return response;
      }),
      catchError(err => {
        this.closeAndClear();
        return of(err);
      })
    );
  }

  getUserObservable() {
    return this.loggedUserSubject.asObservable();
  }

  getUserValue(): User {
    return this.loggedUserSubject?.value ?? new User();
  }

  isLogged() {
    return this.isLoggedIn;
  }

  saveGridConfig(gridConfig: UserGridConfigRequest) {
    return this.httpClient
      .post<User>(`${this.baseUrl}/configuracao-grid`, gridConfig)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  updateGridConfig(gridConfig: UserGridConfigRequest): Observable<any> {
    return this.httpClient
      .put<User>(`${this.baseUrl}/configuracao-grid`, gridConfig)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getGridConfig(id: number): Observable<any> {
    return this.httpClient
      .get<User>(`${this.baseUrl}/configuracao-grid/${id}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getLicencas(): Observable<any> {
    return this.httpClient.get<User>(`${this.baseUrl}/licencas`).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  deleteGridConfig(id: number): Observable<any> {
    return this.httpClient
      .delete<User>(`${this.baseUrl}/configuracao-grid/${id}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  list(params?: UserFilter): Observable<DefaultResponse<User>> {
    let queryParams = '';
    if (params) {
      let arrayIdMaster = '';
      params?.idUsuarios?.forEach((id, index) => {
        if (index === params?.idUsuarios.length - 1) {
          arrayIdMaster = arrayIdMaster.concat(`${id}`);
        } else {
          arrayIdMaster = arrayIdMaster.concat(`${id},`);
        }
      });
      queryParams += '?';
      for (const key in params) {
        if (params[key] !== null && params[key] !== undefined) {
          if (key === 'idUsuarios') {
            queryParams += `${key}=${arrayIdMaster}&`;
          } else {
            queryParams += `${key}=${params[key]}&`;
          }
        }
      }
      queryParams = queryParams.slice(0, -1);
    }

    return this.httpClient
      .get<DefaultResponse<User>>(`${this.baseUrl + queryParams}`)
      .pipe(
        catchError(err => {
          return of(err);
        })
      );
  }

  enable(id: number): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/${id}/ativar`, {}).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  disable(id: number): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/${id}/inativar`, {}).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  atachCompanies(userId: number, empresas: number[]): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl}/${userId}/empresas`, { empresas: empresas })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  atachCompaniesNewUser(
    userId: number,
    object: VincularEmpresaUsuarioRequest
  ): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl}/${userId}/vincular-empresas`, object)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getUserByEmailOrLogin(
    param: string,
    campo: string
  ): Observable<DefaultResponse<User>> {
    return this.httpClient
      .get<
        DefaultResponse<User>
      >(`${this.baseUrl}/validar-existente/${campo}/${param}`, {})
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listUserGroup(): Observable<DefaultResponse<Grupo>> {
    return this.httpClient
      .get<DefaultResponse<Grupo>>(`${this.baseUrl}/grupo`, {})
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  updateUserGroup(grupo: Grupo[]) {
    return this.httpClient.put(`${this.baseUrl}/grupo`, [...grupo]).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  newUserGroup(groupName: string) {
    return this.httpClient
      .post(`${this.baseUrl}/grupo`, { descricao: groupName, indAtivo: true })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  deleteUserGroup(id: number) {
    return this.httpClient.delete(`${this.baseUrl}/grupo/${id}`).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  register(object): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}`, object).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  update(user: any): Observable<any> {
    const { id, ...rest } = user;
    return this.httpClient.put(`${this.baseUrl}/${id}`, rest).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/${id}`).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  listUserGroupPermissions(id: number): Observable<DefaultResponse<any>> {
    return this.httpClient
      .get<DefaultResponse<any>>(`${this.baseUrl}/grupo/${id}/permissoes`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  changeMaster(idMaster: Number) {
    return this.httpClient
      .get<User>(`${this.baseUrl}/atualizar-master/${idMaster}`, {})
      .pipe(
        map((user: User) => {
          this.setTimeToken(user);
          this.setSession(user);
          return user;
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  listUserLicenses(
    id: number
  ): Observable<DefaultResponse<GerenciamentoLicencaModulo>> {
    return this.httpClient
      .get<
        DefaultResponse<GerenciamentoLicencaModulo>
      >(`${this.baseUrl}/${id}/gerenciar-licencas`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  updateUserLicenses(
    id: number,
    data: GerenciamentoLicencaModuloRequest
  ): Observable<any> {
    return this.httpClient
      .put<GerenciamentoLicencaModuloRequest>(
        `${this.baseUrl}/${id}/gerenciar-licencas`,
        data
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  changePassword(obj: any, externalPage: boolean) {
    const { novaSenha, confirmarSenha, chave, idUsuario } = obj;

    if (externalPage) {
      return this.httpClient.post<any>(`${this.baseUrl}/alterar-senha`, {
        novaSenha,
        confirmarSenha,
        chave,
        idUsuario
      });
    } else {
      return this.httpClient.put<any>(`${this.baseUrl}/alterar-senha-logado`, {
        senha: novaSenha,
        confirmaSenha: confirmarSenha
      });
    }
  }

  validateKeyPassword(
    chave: string
  ): Observable<DefaultResponse<ValidarChaveSenhaResponse>> {
    return this.httpClient
      .get<
        DefaultResponse<ValidarChaveSenhaResponse>
      >(`${this.baseUrl}/validar-chave-senha?chave=${chave}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  recoveryPassword(login: any): Observable<DefaultResponse<any>> {
    return this.httpClient
      .get<
        DefaultResponse<any>
      >(`${this.baseUrl}/redefinir-senha?login=${login}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  authorizeExistingUser(hash: string): Observable<any> {
    return this.httpClient
      .post(`${this.baseUrl}/autorizar-existente?hash=${hash}`, {})
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listSistemUserByGroup(
    group: string
  ): Observable<DefaultResponse<UsuarioSistema>> {
    return this.httpClient
      .get<DefaultResponse<UsuarioSistema>>(`${this.baseUrl}/sistema/${group}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  validateCode(codigo: number, email: string): Observable<any> {
    return this.httpClient
      .post(`${this.baseUrl}/validar-codigo`, {
        codigo,
        email
      })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  emailValidation(email: string): Observable<any> {
    return this.httpClient
      .post(`${this.baseUrl}/solicitar-validacao-email`, {
        email
      })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  updateUserRegister(obj: any): Observable<any> {
    const { login, apelido, celular, setor } = obj;
    return this.httpClient
      .put(`${this.baseUrl}/atualizar-dados-recadastramento`, {
        login,
        apelido,
        celular,
        setor
      })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  updateUserLogged(user: any): Observable<any> {
    const { nome, email, celular } = user;
    return this.httpClient
      .put<User>(`${this.baseUrl}/alterar-dados`, {
        nome,
        email,
        celular
      })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listDesableReason(): Observable<any> {
    return this.httpClient
      .get<CompanyDisableReason>(`${this.baseUrl}/motivo-inativacao`)
      .pipe(
        catchError(err => {
          return of(err);
        })
      );
  }

  activateUser(obj: any): Observable<any> {
    const { idMotivo, observacao, id, indAtivo } = obj;
    const res = indAtivo === false ? 'ativar' : 'inativar';
    return this.httpClient
      .put<CompanyDisableReason>(`${this.baseUrl}/${id}/${res}`, {
        idMotivo,
        observacao
      })
      .pipe(
        catchError(err => {
          return of(err);
        })
      );
  }

  getLog(userId: number): Observable<any> {
    return this.httpClient
      .get<any>(`${this.baseUrl}/${userId}/log-ativacao`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listFaturas(): Observable<DefaultResponse<Faturas>> {
    return this.httpClient.get<DefaultResponse<Faturas>>(`${this.baseUrl}/faturas`).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
  }
}
