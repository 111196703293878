import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatusSefazEnum } from 'core/enums/status-sefaz.enum';
import { User } from 'core/models/entities/user/user';
import { SidebarFiltersService } from 'core/services/sidebar-filters/sidebar-filters.service';
import { MessageService } from 'primeng/api';
import { Subscription, distinct, lastValueFrom } from 'rxjs';

import { IconComponent } from '../../core/components/icon/icon.component';
import { StatusSefaz } from '../../core/models/entities/status-sefaz/status-sefaz';
import { AuthService } from '../../core/services/auth/auth.service';
import { CompanyService } from '../../core/services/company/company.service';
import { LoadingService } from '../../core/services/loading/loading.service';
import { NfeService } from '../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  filterValues: any;
  dataSource: any;
  countNfe: number;
  pageTitle: string;
  statuSefazList: StatusSefaz[];
  readonly statusSefazOtimo = StatusSefazEnum.OTIMO;
  readonly statusNotaNaoCaptando: 2;
  withTemplateVisible: boolean;
  @ViewChildren(IconComponent, { read: ElementRef })
  icons: QueryList<ElementRef>;
  currentTooltip: any;
  toggleVisibileSefaz: boolean;
  subscription: Subscription[] = [];
  filterVisible: boolean;
  loggedUser: User;

  constructor(
    private companyService: CompanyService,
    private nfeService: NfeService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private sidebarFiltersService: SidebarFiltersService
  ) {}

  async ngOnInit() {
    await this.getLoggedUser();
    this.listenSidebarFilterActions();
    this.listenUserLogged();
    this.sendAuthorizationNewUser();
  }

  sendAuthorizationNewUser() {
    this.route.queryParams.subscribe(async param => {
      if (param.hasOwnProperty('hashAutorizacao')) {
        const response = await lastValueFrom(
          this.authService.authorizeExistingUser(param['hashAutorizacao'])
        );

        if (response?.mensagem) {
          const user = await lastValueFrom(
            this.authService.refreshToken(this.authService.getUserValue())
          );

          if (user) {
            this.authService.setSession(user);
          }

          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Sucesso',
            key: 'global-toast',
            detail: response?.mensagem
          });
        }
      }
    });
  }

  async reload() {
    this.loadingService.open();
    await this.getCompanies();
    await this.getStatusSefaz();
    this.loadingService.close();
  }

  listenUserLogged() {
    const subscription = this.authService
      .getUserObservable()
      .pipe(distinct(res => res?.apiKey))
      .subscribe(async res => {
        if (res) {
          if (!res.indAdministradorSistema) {
            await this.reload();
          }
        }
      });

    this.subscription.push(subscription);
  }

  async getStatusSefaz() {
    const response = await lastValueFrom(this.nfeService.listStatusSefaz());
    this.statuSefazList = response.dados as StatusSefaz[];
  }

  async getCompanies() {
    this.countNfe = 0;
    var filtroPorMaster = '';
    var filtroPorCNPJ = '';

    if (this.loggedUser.indAdministradorSistema) {
      filtroPorMaster =
        this.filterValues.idUsuariosMaster != null
          ? this.filterValues.idUsuariosMaster.join(',')
          : '';
      filtroPorCNPJ =
        this.filterValues.cnpjEmpresas != null
          ? this.filterValues.cnpjEmpresas?.replace(/[^\d]+/g, '')
          : '';

      if (
        this.filterValues.cnpjEmpresas != undefined &&
        this.filterValues.cnpjEmpresas != '' &&
        filtroPorCNPJ == ''
      ) {
        // O usuário preencheu o campo de CNPJ, mas preencheu apenas com letras, pois após a limpeza do valor enviado, a variável ficou vazia
        this.messageService.add({
          severity: 'warn',
          summary: 'Alerta!',
          key: 'global-toast',
          sticky: true,
          detail: 'CNPJ Inválido. Por favor verifique o CNPJ informado.'
        });
        this.loadingService.close();
        return false;
      }
    }

    const response = await lastValueFrom(
      this.loggedUser.indAdministradorSistema
        ? this.companyService.listByUsersCnpjs(
            true,
            filtroPorMaster,
            filtroPorCNPJ
          )
        : this.companyService.list()
    );

    this.dataSource = response?.dados?.map(company => {
      this.countNfe += company?.totalNfeArmazenadas;

      const { diasExpiracao, tipoCertificado } = company;
      const max = tipoCertificado === 'A1' ? 365 : 1095;
      const perc = (100 * (max - diasExpiracao)) / max;

      this.pageTitle = ` ${
        response?.paginacao?.totalDeRegistro
      } empresa(s) - ${this.thousandSeparator(
        this.countNfe
      )} notas fiscais armazenadas`;
      return { ...company, progress: perc };
    });
  }

  thousandSeparator(value): string {
    const pattern = /(\d)(?=(\d{3})+(?!\d))/g;
    const repl = '$1.';
    const valueString = String(value);
    return valueString.replace(pattern, repl);
  }

  calculateProgressBarValue(row): number {
    const { diasExpiracao, tipoCertificado } = row?.data;
    const max = tipoCertificado === 'A1' ? 365 : 1095;
    return (100 * (max - diasExpiracao)) / max;
  }

  formatBarLabel() {
    return ``;
  }

  formatLabelProgressbar(row): string {
    const { diasExpiracao, dataValidade } = row?.data;
    const stringDate = new Date(dataValidade).toLocaleDateString();
    return diasExpiracao > 0
      ? `Validade: ${stringDate} (faltam ${diasExpiracao} dias)`
      : `Expirado em ${stringDate} `;
  }

  refreshDataGrid(): void {
    this.getCompanies();
  }

  toggleWithTemplate(data) {
    const { id, status } = data;
    this.currentTooltip = undefined;
    const elementArray = this.icons.toArray();
    this.currentTooltip = elementArray.find(el => el.nativeElement.id == id);
    this.withTemplateVisible = !this.withTemplateVisible;
  }

  toggleWithTemplateSefaz() {
    this.toggleVisibileSefaz = !this.toggleVisibileSefaz;
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  toggleSidebarFilter() {
    this.filterVisible = !this.filterVisible;
  }

  listenSidebar(event) {
    this.filterVisible = event.visible;
  }

  async listenFilterValues(filters: any) {
    this.filterValues = filters;
    await this.reload();
    this.sidebarFiltersService.close();
  }

  send = () => this.sidebarFiltersService.send();

  reset = () => this.sidebarFiltersService.reset();
  openModalUpdateUserRegister: boolean;

  async getLoggedUser() {
    this.authService.getUserObservable().subscribe(user => {
      if (user) {
        this.loggedUser = user;
        this.openModalUpdateUserRegister =
          this.loggedUser.indSolicitaRecadastro;
      }
    });
  }

  listenSidebarFilterActions() {
    this.sidebarFiltersService.receive().subscribe(action => {
      if (action?.close) {
        this.filterVisible = false;
      }
    });
  }
}
