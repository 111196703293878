<section id="container-topbar">
  <div id="logo-topbar-container" class="cursor-pointer" routerLink="/">
    <img
      id="img-logo-topbar"
      ngSrc="../../../../assets/logos/logo-confear.png"
      alt=""
      height="67"
      width="280" />
    <div id="container-user-info-detail-mobile">
      <em class="pi pi-user"></em>
      <p>Olá, {{ user.nome }}</p>
    </div>
  </div>

  <div class="env-tag-hml" *ngIf="env.test">{{ env.name }}</div>
  <div class="env-tag-dev" *ngIf="env.development">{{ env.name }}</div>

  <div id="container-user">
    <!--Comentado por Rafael 07/06/2023 - Pois a implementação da mensageria será feita após o MVP-->
    <!--<div id="container-notification-icone">
      <em
        #iconDropdown
        pBadge
        severity="danger"
        class="pi pi-bell"
        (click)="toggleNotification()"
        value="8"></em>
      <app-notification-dropdown
        [trigger]="iconDropdown"></app-notification-dropdown>
    </div>-->

    <section id="container-user-info">
      <div id="vertical-line"></div>

      <div id="container-user-info-detail" #container>
        <em class="pi pi-user" (click)="userMenu.toggle($event)"></em>
        <div id="container-company-email">
          <p id="company-name" (click)="userMenu.toggle($event)">
            {{ user.nome.toUpperCase() }}
          </p>
          <p id="email" (click)="userMenu.toggle($event)">
            Login: {{ user.login.toLowerCase() }}
          </p>
          <p id="master" (click)="openModalSelectMaster()">
            Master: {{ masterLogged.nome | titlecase }} (Cód:
            {{ masterLogged.id }})
          </p>
        </div>
        <em class="material-icons-outlined" (click)="userMenu.toggle($event)">
          arrow_drop_down
        </em>
        <p-tieredMenu
          [appendTo]="container"
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          styleClass="menu-user-info"
          #userMenu
          [model]="itemsUserMenu"
          [popup]="true"></p-tieredMenu>
      </div>
    </section>
  </div>
</section>
