import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-fragment',
  template: `
    <section
      *ngIf="loading"
      class="container-loading-fragment"
      [style.height]="height">
      <img
        id="logo-img"
        src="../../../../assets/logos/logo-confear.png"
        alt="logo confear" />
      <p-progressSpinner
        [style]="{ width: '50px', height: '50px' }"
        styleClass="custom-spinner"
        strokeWidth="8"
        fill="transparent"
        animationDuration=".5s"></p-progressSpinner>
    </section>
  `,
  styleUrls: ['./loading-fragment.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule]
})
export class LoadingFragmentComponent {
  @Input() loading: boolean;
  @Input() height;
  constructor() {}
}
