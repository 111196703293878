<app-modal
  modalTitle="AVISO IMPORTANTE"
  (closeModal)="close()"
  [width]="'70vw'"
  [display]="open">
  <div class="font-bold content">
    <i class="pi pi-exclamation-triangle icon-warn"></i>
    <div class="text">
      <p>
        Prezado Cliente,
        <br />
      </p>
      <p>
        Nos últimos dias o serviço de notas fiscais da SEFAZ está muito
        instável, acarretando lentidão na captura das notas pelo CONFEAR. Nosso
        time de especialistas está acompanhando de perto essa situação,
        monitorando e realizando as ações possíveis.
        <br />
      </p>

      <p id="text-orange">
        Você sabia que o CONFEAR possui um diferencial, que é a Contingência de
        Captação?
        <br />
      </p>

      <p>
        Todos os clientes possuem esse serviço, basta configurá-lo. O serviço de
        contingência, é uma alternativa a captação de notas via SEFAZ, onde as
        notas são captadas através do e-mail.
        <br />
      </p>

      <p>
        <a
          id="text-blue"
          href="https://faq.egtec.com.br/contingencia-de-captacao-de-notas"
          target="_blank">
          Clique aqui
        </a>
        para entender como é o funcionamento e como você pode configurar esse
        serviço para suas lojas.
      </p>
    </div>
  </div>
</app-modal>
