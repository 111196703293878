import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { MenuItem } from '../../models/entities/menu/menuItem';
import { IOMenuItem } from '../../models/interfaceObjects/menu/IOMenuItem';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private display: boolean;
  private displaySubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  currentMenuItemValue: IOMenuItem | undefined;
  private currentMenuItemSubject: BehaviorSubject<IOMenuItem | null> =
    new BehaviorSubject<IOMenuItem | null>(null);

  items: MenuItem[] | null;
  private itemsSubject: BehaviorSubject<MenuItem[] | null> =
    new BehaviorSubject<MenuItem[] | null>(null);

  constructor(private localStorageService: LocalStorageService) {}

  open(): void {
    this.displaySubject.next(true);
    this.display = true;
  }

  close(): void {
    this.displaySubject.next(false);
    this.display = false;
  }

  getDisplayMenuObservable(): Observable<boolean> {
    return this.displaySubject.asObservable();
  }

  setCurrentMenuItem(item: IOMenuItem) {
    this.currentMenuItemValue = item;
    this.localStorageService.updateProperty('currentMenu', item);
  }

  listenStorageChanges = () =>
    this.localStorageService.getObservable().subscribe(storage => {
      if (!storage?.user) {
        this.clear();
        return;
      }

      if (storage?.menu) {
        this.items = storage.menu;
        this.itemsSubject.next(this.items);
      }

      if (storage?.currentMenu) {
        const isEquals =
          this.currentMenuItemValue &&
          this.currentMenuItemValue === storage.currentMenu;

        if (!isEquals) {
          this.currentMenuItemValue = storage.currentMenu;
        }

        this.currentMenuItemSubject.next(storage.currentMenu);
      }
    });

  getCurrentMenuItemObservable = (): Observable<IOMenuItem | null> =>
    this.currentMenuItemSubject.asObservable();

  getCurrentItemValue = () => this.currentMenuItemValue;

  getItemsObservable = (): Observable<MenuItem[] | null> =>
    this.itemsSubject.asObservable();

  getItemsValue = () => this.items;

  private clear = () => {
    this.currentMenuItemValue = undefined;
    this.currentMenuItemSubject.next(null);
    this.items = null;
    this.itemsSubject.next(null);
  };
}
