<form id="form-change-password" class="grid grid-nogutter" [formGroup]="form">
  <div class="md:col-9 col-10 mb-3" *ngIf="externalPage && !chaveExpirada">
    <h4 class="m-0">Dados do Usuário</h4>
    <p class="m-0 mt-1">Login: {{ login }}</p>
    <p class="m-0 mt-1">E-mail: {{ email }}</p>
  </div>
  <ng-container *ngIf="!chaveExpirada">
    <div class="md:col-9 col-10 mb-2">
      <h4 class="m-0 mb-1">Nova senha</h4>
      <div class="p-inputgroup mb-1">
        <p-inputgroup>
          <p-inputgroup-addon>
            <i class="pi pi-lock"></i>
          </p-inputgroup-addon>
          <input
            id="newPassword"
            autocomplete="off"
            formControlName="newPassword"
            [type]="toggleNewPassword ? 'text' : 'password'"
            pInputText
            placeholder="Digite a nova senha"
            name="password" />
          <p-inputgroup-addon (click)="toggleNewPassword = !toggleNewPassword">
            <i
              class="pi"
              [ngClass]="toggleNewPassword ? 'pi-eye' : 'pi-eye-slash'"></i>
          </p-inputgroup-addon>
        </p-inputgroup>
      </div>
      <div *ngIf="newPassword?.invalid && submitted" class="p-error">
        <em class="pi pi-exclamation-circle mr-1"></em>
        <small class="">Por favor, informe uma senha</small>
      </div>
    </div>
    <div class="md:col-9 col-10 mb-3">
      <h4 class="m-0 mb-1">Confirmar senha</h4>
      <div class="p-inputgroup mb-1">
        <p-inputgroup>
          <p-inputgroup-addon>
            <i class="pi pi-lock"></i>
          </p-inputgroup-addon>
          <input
            name="password"
            id="confirmPassword"
            autocomplete="off"
            [type]="toggleConfirmPassword ? 'text' : 'password'"
            formControlName="confirmNewPassword"
            pInputText
            placeholder="Repita a senha" />
          <p-inputgroup-addon
            (click)="toggleConfirmPassword = !toggleConfirmPassword">
            <i
              class="pi"
              [ngClass]="toggleConfirmPassword ? 'pi-eye' : 'pi-eye-slash'"></i>
          </p-inputgroup-addon>
        </p-inputgroup>
      </div>
      <div *ngIf="confirmPassword?.invalid && submitted" class="p-error">
        <em class="pi pi-exclamation-circle mr-1"></em>
        <small class="">Por favor, informe uma senha</small>
      </div>
      <div
        *ngIf="form?.errors?.hasOwnProperty('passwordEqual') && submitted"
        class="p-error">
        <em class="pi pi-exclamation-circle mr-1"></em>
        <small class="">As duas senhas estão diferentes.</small>
      </div>
    </div>
    <div class="md:col-9 col-10 md:mb-3">
      <p-button
        label="Confirmar"
        iconPos="left"
        icon="pi pi-check"
        type="button"
        (click)="send()"
        styleClass="w-50"
        class="btn-azul-padrao"
        id="btn-confirm-password"></p-button>
      <div class="container-info font-bold warning text-blue regras-senha">
        <span class="pi pi-info-circle mr-2"></span>
        <span>
          A sua senha deve possuir entre 8 e 15 caracteres, e ter no mínimo uma
          letra maiúscula, uma letra minúscula, um número e um caracter especial
          (Ex. #, ? , $ , *).
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="chaveExpirada">
    <div class="md:col-9 col-10 mb-3">
      O link utilizado para redefinição de senha expirou. Utilize o botão abaixo
      para realizar uma nova solicitação.
    </div>
    <div class="md:col-9 col-10 md:mb-3">
      <p-button
        id="btn-new-link"
        type="button"
        (click)="generateNewLink()"
        class="p-button md:col-6 col-12 md:mb-6 btn-azul-padrao">
        <em class="pi pi-check mr-2"></em>
        <p class="m-0">Redefinir minha senha</p>
      </p-button>
    </div>
  </ng-container>
</form>
