export function diffInDays(start, end) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function subDate(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
}

export function sumDate(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function formatarDataParaTimezone(data: Date): string {
  const timeZone = 'America/Sao_Paulo';
  // Formatador para o timezone especificado
  const formatador = new Intl.DateTimeFormat('pt-BR', {
    timeZone,
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  // Formatando a data
  const dataFormatada = formatador.format(data);

  // Ajustando para o formato ISO
  const [dataPart, horaPart] = dataFormatada.split(' ');
  const [dia, mes, ano] = dataPart.split('/');
  const dataIso = `${ano.replace(',', '')}-${mes}-${dia}T${horaPart}-03:00`; // Assumindo horário fixo de -03:00

  return dataIso;
}
