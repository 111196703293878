import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { SefazService } from 'src/app/core/services/sefaz/sefaz.service';

import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription, lastValueFrom } from 'rxjs';

import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-sefaz-situacao',
  template: `
    <app-modal (closeModal)="close()" [display]="open">
      <section header>
        Verificar situação da NF-e
        <span class="nfe-key-header-modal">{{ nfe?.numeroNfe }}</span>
        na SEFAZ estadual
      </section>
      <app-loading-fragment
        [height]="'150px'"
        [loading]="loading"></app-loading-fragment>
      <section *ngIf="!loading" class="content-icon-message">
        <div class="content">
          <span class="material-icons-outlined icon">error</span>
          <span>{{ status?.descricao }}</span>
        </div>
      </section>
    </app-modal>
  `,
  standalone: true,
  imports: [ModalComponent, LoadingFragmentComponent, NgIf]
})
export class ModalSefazSituacaoComponent implements OnInit, OnDestroy {
  open: boolean;
  nfe: any;
  subscription: Subscription[] = [];
  status: { id: number; descricao: string; mensagem: string };
  loading: boolean;

  constructor(
    private modalService: ModalService,
    private sefazService: SefazService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService
      .listenChanges()
      .subscribe(async res => {
        if (
          res?.id ===
            CONFEAR_PERMISSIONS.VERIFICAR_SITUACAO_NFE_SEFAZ_ESTADUAL &&
          res.open
        ) {
          this.loading = true;
          this.nfe = res.data;
          this.open = true;
          await this.getStatus();
        }
      });

    this.subscription.push(subscription);
  }

  async getStatus() {
    const cnpjDestinatario = this.nfe?.cnpjDestinatario
      ? this.nfe?.cnpjDestinatario
      : this.nfe?.destinatario?.cnpj;
    const response = await lastValueFrom(
      this.sefazService.getStatus(this.nfe.chaveNfe, cnpjDestinatario)
    );
    if (!response?.error) {
      this.status = response;
    }
    this.loading = false;
  }

  close() {
    this.open = false;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
