import { MenuComponent } from '@shared/components/menu/menu.component';
import { TopbarComponent } from '@shared/components/topbar/topbar.component';
import { Button } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { MenuService } from 'src/app/core/services/menu/menu.service';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Subscription } from 'rxjs';

import { DashboardComponent } from '../dashboard/dashboard.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-main',
  template: `
    <section id="main-container">
      <app-topbar></app-topbar>
      <div id="container-menu-breadcrumb-dashboard-footer">
        <app-menu></app-menu>
        <div class="container-icon">
          <em
            (click)="toggleMenu()"
            id="menu-icon"
            [style.right.px]="[display ? -10 : -17]"
            [ngClass]="[
              display ? 'pi pi-chevron-left' : 'pi pi-chevron-right'
            ]"></em>
        </div>
        <div id="main-content">
          <!--      <app-breadcrumb></app-breadcrumb>-->
          <app-dashboard></app-dashboard>
          <app-footer></app-footer>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    Button,
    CommonModule,
    MenuComponent,
    RouterModule,
    ToolbarModule,
    ToastModule,
    DashboardComponent,
    FooterComponent,
    TopbarComponent
  ]
})
export class MainComponent implements OnInit, OnDestroy {
  display = true;
  subscription: Subscription[] = [];

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    this.listenDisplayMenu();
  }

  listenDisplayMenu() {
    const subscription = this.menuService
      .getDisplayMenuObservable()
      .subscribe((display: boolean) => {
        this.display = display;
      });

    this.subscription.push(subscription);
  }

  mostrarMenu() {
    this.display = !this.display;
  }

  toggleMenu() {
    this.display ? this.menuService.close() : this.menuService.open();
  }

  ngOnDestroy() {
    this.subscription.forEach(subs => subs.unsubscribe());
  }
}
