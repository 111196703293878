import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionPanel
} from 'primeng/accordion';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { LoadingService } from 'src/app/core/services/loading/loading.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-nfe-resumo-manifestacao',
  templateUrl: './modal-nfe-resumo-manifestacao.component.html',
  standalone: true,
  imports: [
    ModalComponent,
    Accordion,
    PrimeTemplate,
    CommonModule,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    Button
  ],
  styleUrls: ['./modal-nfe-resumo-manifestacao.component.scss']
})
export class ModalNfeResumoManifestacaoComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  data: any;
  subscription: Subscription[] = [];

  totalNfeSelecionadasLabel = 'NF-e selecionadas pelo usuário: ';
  nfeManifestadasLabel = 'NF-e manifestadas: ';
  nfeStatusModificadoLabel =
    'NF-e com apenas o status de manifestação modificado: ';
  nfeNaoManifestadasLabel = 'NF-e não manifestadas: ';
  nfeIgnoradasLabel = 'NF-e ignoradas: ';
  nfeNumbers = new Set<string>();
  nfes: string = '';
  modalName = '';

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.MANIFESTACAO_NFE &&
        res.open &&
        res.name === 'MODAL_RESUMO_MANIFESTACAO'
      ) {
        this.loadingService.close();
        this.modalName = res.name;
        this.data = res.data;
        this.open = true;
        this.handlerData();
        this.getNfes();
      }
    });

    this.subscription.push(subscription);
  }

  extractNfeNumber(array: any[]) {
    if (array.length) {
      array.forEach((nfe: any) => {
        this.nfeNumbers.add(nfe.numero);
      });
    }
  }

  getNfes() {
    this.nfes = '';
    const nfeNumbers = [...this.nfeNumbers];
    nfeNumbers.forEach((nfeNumber, index) => {
      this.nfes =
        index === 0
          ? this.nfes.concat(nfeNumber)
          : this.nfes.concat(`, ${nfeNumber}`);
    });
  }

  handlerData() {
    this.totalNfeSelecionadasLabel = this.totalNfeSelecionadasLabel.concat(
      this.data.totalNfeSelecionadas.toString()
    );

    this.nfeManifestadasLabel = this.nfeManifestadasLabel.concat(
      this.data.nfeManifestadas.length.toString()
    );
    this.extractNfeNumber(this.data.nfeManifestadas);
    this.nfeStatusModificadoLabel = this.nfeStatusModificadoLabel.concat(
      this.data.nfeStatusModificado.length.toString()
    );
    this.extractNfeNumber(this.data.nfeStatusModificado);

    this.nfeNaoManifestadasLabel = this.nfeNaoManifestadasLabel.concat(
      this.data.nfeNaoManifestadas.length.toString()
    );
    this.extractNfeNumber(this.data.nfeNaoManifestadas);

    this.nfeIgnoradasLabel = this.nfeIgnoradasLabel.concat(
      this.data.nfeIgnoradas.length.toString()
    );
    this.extractNfeNumber(this.data.nfeIgnoradas);
  }

  close() {
    this.open = false;
    this.data = undefined;
    this.nfes = '';
    this.nfeNumbers = new Set<string>();
    this.totalNfeSelecionadasLabel = 'NF-e selecionadas pelo usuário: ';
    this.nfeManifestadasLabel = 'NF-e manifestadas: ';
    this.nfeStatusModificadoLabel =
      'NF-e com apenas o status de manifestação modificado: ';
    this.nfeNaoManifestadasLabel = 'NF-e não manifestadas: ';
    this.nfeIgnoradasLabel = 'NF-e ignoradas: ';
  }

  ngOnDestroy() {
    this.close();
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
