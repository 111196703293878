import { DrawerFiltersConfig } from '@core/models/interfaceObjects/drawer-filters-config/drawer-filters-config';

import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerFiltersService {
  private subject: BehaviorSubject<DrawerFiltersConfig> =
    new BehaviorSubject<DrawerFiltersConfig>({});

  constructor() {}

  send(config?: DrawerFiltersConfig) {
    if (config) {
      this.subject.next(config);
    } else {
      this.subject.next({ send: true });
    }
  }

  reset() {
    this.subject.next({ reset: true });
  }

  close() {
    this.subject.next({ close: true });
  }

  receive(): Observable<DrawerFiltersConfig> {
    return this.subject;
  }
}
