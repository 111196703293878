<form [formGroup]="form" class="container mt-4">
  <span class="text-green">
    Por favor, informe seu e-mail, a partir de agora ele será o seu login no
    novo sistema
  </span>
  <div class="row mb-2 mt-4">
    <label class="custom-label" for="email">E-mail:</label>
    <input
      class="custom-input"
      pInputText
      id="email"
      formControlName="email"
      type="text" />
  </div>
  <div class="row">
    <label class="custom-label" for="emailConfirmation">
      Confirmar e-mail:
    </label>
    <input
      class="custom-input"
      pInputText
      id="emailConfirmation"
      formControlName="emailConfirmation"
      type="text" />
    <p-button
      type="button"
      [disabled]="form.invalid"
      class="btn-azul-padrao ml-2"
      (click)="next()">
      Validar e-mail
    </p-button>
  </div>
</form>
