<form [formGroup]="form" class="container mt-4">
  <div class="row mb-4">
    <label class="custom-label" for="login">E-mail:</label>
    <input
      class="custom-input"
      pInputText
      id="login"
      formControlName="login"
      type="text" />
    <span class="pi pi-check ml-2"></span>
    <span class="text-green ml-2">E-mail validado</span>
  </div>
  <div class="row mb-4">
    <label class="custom-label">Novo login:</label>
    <span class="text-blue">{{ login?.value }}</span>
  </div>
  <div class="row mb-4">
    <label class="custom-label" for="apelido">
      Como você gostaria de ser chamado?
    </label>
    <input
      class="custom-input"
      pInputText
      id="apelido"
      formControlName="apelido"
      type="text" />
  </div>
  <div class="row mb-4">
    <label class="custom-label" for="celular">Tel. Celular:</label>
    <p-inputMask
      [unmask]="true"
      class="custom-input"
      mask="(99) 99999-9999"
      placeholder="(XX) XXXXX-XXXX"
      id="celular"
      formControlName="celular"
      type="text"></p-inputMask>
  </div>
  <div class="row">
    <label class="custom-label" for="celular">Setores:</label>
    <p-select
      styleClass="dropdown-setor"
      appendTo="body"
      optionLabel="descricao"
      optionValue="id"
      formControlName="setor"
      class="dropdown-setor"
      placeholder="Selecione"
      [options]="setores"></p-select>
  </div>
</form>
<div class="row justify-content-center">
  <p-button
    [disabled]="form.invalid"
    class="btn-verde ml-2 mt-4"
    (click)="next()">
    Atualizar dados
  </p-button>
</div>
