import { Button } from 'primeng/button';
import { InputGroup } from 'primeng/inputgroup';
import { InputGroupAddon } from 'primeng/inputgroupaddon';
import { InputText } from 'primeng/inputtext';

import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-senha-alteracao',
  templateUrl: './senha-form-alteracao.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    InputText,
    NgClass,
    InputGroup,
    InputGroupAddon,
    Button
  ],
  styleUrls: ['./senha-form-alteracao.component.scss']
})
export class SenhaFormAlteracaoComponent implements OnInit {
  @Output() formChanges = new EventEmitter();
  @Input() externalPage = true;
  @Input() email: any = '';
  @Input() login: any = '';
  @Input() chaveExpirada: boolean;
  toggleNewPassword = false;
  toggleConfirmPassword = false;
  form: FormGroup = new FormGroup({});
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group(
      {
        newPassword: [null, [Validators.required]],
        confirmNewPassword: [null, [Validators.required]]
      },
      { validators: this.passwordValidator }
    );
  }

  passwordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const newPassword = control.get('newPassword');
    const confirmNewPassword = control.get('confirmNewPassword');

    return newPassword &&
      confirmNewPassword &&
      newPassword.value !== confirmNewPassword.value
      ? { passwordEqual: false }
      : null;
  };

  get newPassword() {
    return this.form.controls['newPassword'];
  }

  get confirmPassword() {
    return this.form.controls['confirmPassword'];
  }

  send() {
    this.submitted = true;
    this.formChanges.emit(this.form);
  }

  generateNewLink() {
    this.router.navigate(['esqueci-minha-senha']);
  }
}
