import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { Nfe } from 'src/app/core/models/entities/nfe/nfe';
import { ModalService } from 'src/app/core/services/modal/modal.service';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { NfeAnaliseComponent } from '../../../../pages/nfe/analise/nfe-analise.component';
import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-nfe-analise',
  template: `
    <app-modal (closeModal)="close()" [display]="open" [width]="'90vw'">
      <section header>Analisar NF-e</section>
      <app-loading-fragment [loading]="loading"></app-loading-fragment>
      <ng-container *ngIf="open && !messageError">
        <app-nfe-analise
          [nfe]="nfe"
          (nfeLoaded)="listenNfeLoading($event)"></app-nfe-analise>
      </ng-container>

      <ng-container *ngIf="messageError">
        {{ messageError }}
      </ng-container>
    </app-modal>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    NfeAnaliseComponent,
    LoadingFragmentComponent
  ]
})
export class ModalNfeAnaliseComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfe: Nfe | undefined;
  subscription: Subscription[] = [];
  messageError: string;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.ANALISAR_NFE && res.open) {
        this.resetField(res.data);
        this.loading = true;
        this.open = true;
      }
    });

    this.subscription.push(subscription);
  }

  resetField(data) {
    this.nfe = undefined;
    setTimeout(() => {
      this.nfe = data;
    }, 1000);
  }

  close() {
    this.loading = false;
    this.open = false;
    this.messageError = '';
  }

  listenNfeLoading(data) {
    this.messageError = '';
    this.loading = false;
    if (data?.error) {
      this.messageError = data?.error;
      this.open = false;
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
