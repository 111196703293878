<app-modal
  [modalTitle]="title"
  width="65vw"
  (closeModal)="close()"
  [display]="open">
  <div class="sub-title">{{ textInfo }}</div>
  <app-page-wrapping>
    <section class="overflow-x-hidden" content>
      <section class="container-home">
        <dx-data-grid
          #dataGrid
          class="grid-container container-log"
          id="gridContainer"
          [hoverStateEnabled]="true"
          [columnMinWidth]="100"
          [allowColumnResizing]="true"
          [dataSource]="dataSource"
          [remoteOperations]="false"
          [allowColumnReordering]="true"
          [rowAlternationEnabled]="true"
          [showBorders]="true">
          <dxo-search-panel
            [width]="240"
            [visible]="true"
            [highlightCaseSensitive]="true"></dxo-search-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-group-panel
            emptyPanelText="Arraste uma coluna para agrupar"
            [visible]="true"></dxo-group-panel>
          <dxi-column
            [width]="120"
            dataType="date"
            alignment="center"
            caption="DATA"
            dataField="dataAcao"
            cellTemplate="data">
            <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            <div class="column-date" *dxTemplate="let cell of 'data'">
              <span>
                {{ cell?.data?.dataAcao | date: 'dd/MM/yyyy' }}
              </span>
            </div>
          </dxi-column>

          <dxi-column
            class="columnLogin"
            [minWidth]="130"
            dataType="string"
            alignment="left"
            dataField="loginUsuarioAcao"
            caption="USUÁRIO RESPONSÁVEL"
            cellTemplate="nomeLogin">
            <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
          </dxi-column>
          <div *dxTemplate="let cell of 'nomeLogin'">
            <span>
              <div class="text-capitalize">
                {{ cell?.data?.nomeUsuarioAcao }}
              </div>
              <div class="text-lowercase">
                {{ '(' + cell?.data?.loginUsuarioAcao + ')' }}
              </div>
            </span>
          </div>

          <dxi-column
            [visible]="false"
            dataType="string"
            alignment="left"
            [minWidth]="200"
            dataField="nomeUsuarioAcao"></dxi-column>

          <dxi-column
            [width]="120"
            dataType="string"
            alignment="center"
            dataField="acao"
            caption="AÇÃO"
            cellTemplate="acaoTemplate">
            <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
          </dxi-column>
          <div
            class="capitalize-first-letter"
            *dxTemplate="let cell of 'acaoTemplate'"
            [ngClass]="{
              acaoAtivou: cell?.data?.acao === 'Ativou',
              acaoInativou: cell?.data?.acao === 'Inativou'
            }">
            {{ cell?.data?.acao }}
          </div>

          <dxi-column
            [minWidth]="130"
            dataType="string"
            alignment="left"
            dataField="motivo"
            caption="MOTIVO/OBS"
            cellTemplate="motivoObs">
            <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
          </dxi-column>
          <div *dxTemplate="let row of 'motivoObs'">
            <span>
              {{ row?.data?.motivo }}
              <div class="custom-observacao capitalize-first-letter">
                {{ row?.data?.observacao }}
              </div>
            </span>
          </div>

          <dxi-column
            [visible]="false"
            [minWidth]="130"
            dataType="string"
            alignment="left"
            dataField="observacao"></dxi-column>
        </dx-data-grid>
      </section>
    </section>
  </app-page-wrapping>
  <section class="container-footer-modal" footer>
    <button
      pButton
      title="Fechar"
      (click)="close()"
      class="btn-dark-red mr-2"
      type="button"
      label="Fechar"
      icon="pi pi-times"></button>
  </section>
</app-modal>
