import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custoReal',
  standalone: true
})
export class CustoRealPipe implements PipeTransform {
  transform(custoReal: number, valorMedio: number): unknown {
    const diff = Math.abs(custoReal - valorMedio);
    let percent = (diff * 100) / valorMedio;
    if (!valorMedio) {
      percent = diff * 100;
    }
    return custoReal > valorMedio
      ? `O Custo Real é ${percent.toFixed(
          2
        )}% maior  do que o Valor Médio de mercado`
      : `O Custo Real é ${percent.toFixed(
          2
        )}% menor do que o Valor Médio de mercado`;
  }
}
