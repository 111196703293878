import { CONFEAR_PERMISSIONS } from 'src/app/core/consts/CONFEAR-PERMISSIONS';
import { ManifestacaoLog } from 'src/app/core/models/entities/nfe/manifestacao-log';
import { Nfe } from 'src/app/core/models/entities/nfe/nfe';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NfeService } from 'src/app/core/services/nfe/nfe.service';

import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { IconeComponent } from '../../icone/icone.component';
import { LoadingFragmentComponent } from '../../loading-fragment/loading-fragment.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-modal-nfe-log-manifestacao',
  template: `
    <app-modal
      modalTitle="Histórico de manifestações NF-e"
      [nfeNumber]="nfe?.numeroNfe"
      (closeModal)="close()"
      [display]="open">
      <app-loading-fragment
        height="200px"
        [loading]="loading"></app-loading-fragment>
      <div *ngIf="!errorMessage" class="container-modal-manifestation-log">
        <section class="header-manifestation-log">
          <div class="cell">Evento</div>
          <div class="cell">Data</div>
          <div class="cell">Login</div>
        </section>
        <section *ngFor="let log of logs" class="row-manifestation-log">
          <div class="cell">
            <app-icone
              fontSize="23px"
              marginRight="5px"
              [icon]="log.icone"></app-icone>
            <span class="event-description">{{ log.evento }}</span>
          </div>
          <div class="cell">{{ log.data | date: 'dd/MM/yyyy HH:mm' }}</div>
          <div class="cell">{{ log.login }}</div>
        </section>
      </div>

      <div *ngIf="errorMessage">
        <span>{{ errorMessage }}</span>
      </div>
    </app-modal>
  `,
  standalone: true,
  imports: [
    NgIf,
    ModalComponent,
    LoadingFragmentComponent,
    IconeComponent,
    NgForOf,
    DatePipe
  ],
  styleUrls: ['./modal-nfe-log-manifestacao.component.scss']
})
export class ModalNfeLogManifestacaoComponent implements OnInit {
  open: boolean;
  loading: boolean;
  nfe: Nfe;
  subscription: Subscription[] = [];
  logs: ManifestacaoLog[];
  errorMessage: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.HISTORICO_MANIFESTACAO && res.open) {
        this.nfe = res.data;
        this.getManifestationLogs();
      }
    });

    this.subscription.push(subscription);
  }

  getManifestationLogs() {
    this.nfeService
      .listManifestationLog(this.nfe.chaveNfe)
      .subscribe((logs: any) => {
        this.errorMessage = '';
        if (logs.dados) {
          this.logs = logs.dados as ManifestacaoLog[];
          this.loading = true;
          this.open = true;
        }

        if (logs?.error) {
          this.errorMessage = logs.error?.mensagem?.reduce((acc, cc) => {
            this.errorMessage.concat(cc);
          });
        }
        this.loading = false;
      });
  }

  close() {
    this.loading = false;
    this.open = false;
    this.errorMessage = '';
  }
}
