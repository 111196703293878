import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../core/models/entities/company/company';
import { SoftwarePdv } from '../../core/models/entities/company/softwarePdv';
import { Servidor } from '../../core/models/entities/servidor-integracao/servidor';
import { ServidorIntegracaoResponse } from '../../core/models/responses/servidor-integracao/servidor-integracao-response';
import { EgtecService } from '../../core/services/egtec/egtec.service';
import { LoadingService } from '../../core/services/loading/loading.service';
import { PluginService } from '../../core/services/plugin/plugin.service';
import { ServidorIntegracaoService } from '../../core/services/servidor-integracao/servidor-integracao.service';

@Component({
  selector: 'app-pdv-integration-management',
  templateUrl: './pdv-integration-management.component.html',
  styleUrls: ['./pdv-integration-management.component.scss']
})
export class PdvIntegrationManagementComponent implements OnInit {
  dataSourceServer: Servidor[];
  dataSourceCompanies: Company[];
  data: ServidorIntegracaoResponse;
  statusFilter = ['Captando', 'Não captando'];
  softwaresPdv: SoftwarePdv[] = [];
  selectedServer: Servidor;
  showModalRegister: boolean;
  showModalDelete: boolean;
  showModalAttach: boolean;

  constructor(
    private servidorIntegracaoService: ServidorIntegracaoService,
    private loadingService: LoadingService,
    private egtecService: EgtecService,
    private pluginService: PluginService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    await this.reload();
  }

  async reload() {
    this.loadingService.open();
    await this.list();
    void this.getSoftaresPdv();
    this.loadingService.close();
  }

  async list() {
    const response = await lastValueFrom(this.servidorIntegracaoService.list());

    if (response?.dados) {
      this.dataSourceServer = response.dados?.servidores;
      this.dataSourceCompanies = response.dados?.empresasNaoVinculadas.map(
        empresa => {
          const cnpjMask = empresa.cnpj;
          return { ...empresa, cnpjMask };
        }
      );
      this.data = response.dados;
    }
  }

  async getSoftaresPdv() {
    const response = await lastValueFrom(this.egtecService.listSoftwares(true));
    if (response?.dados) {
      this.softwaresPdv = response?.dados.map(software => {
        return { id: software.id, descricao: software.descricao };
      });
    }
  }

  openModalRegister() {
    this.showModalRegister = true;
  }

  openModalAttach(event) {
    this.selectedServer = event;
    this.showModalAttach = true;
  }

  listenModalRegister(event) {
    if (event?.register) {
      this.reload();
    }
    this.showModalRegister = false;
  }

  openModalDelete(event) {
    this.selectedServer = event;
    this.showModalDelete = true;
  }

  listenModalDelete(event) {
    if (event.delete) {
      this.reload();
    }
    this.showModalDelete = false;
  }

  listenModalAttach(event) {
    if (event.attach) {
      this.reload();
    }
    this.showModalAttach = false;
  }

  getEmpresasVinculadas(data: ServidorIntegracaoResponse) {
    return data?.totais?.empresas - data?.totais?.empresasNaoVinculadas;
  }

  disableCreateServer(data: ServidorIntegracaoResponse) {
    return data?.totais?.empresasNaoVinculadas <= 0;
  }

  async download(cell) {
    this.loadingService.open();
    const response = await lastValueFrom(
      this.pluginService.download(cell?.data.chaveComputador)
    );
    this.loadingService.close();
    if (response?.dados) {
      window.open(response.dados?.url, '_blank');
    }
  }
}
