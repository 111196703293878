import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  open = () => this.loadingSubject.next(true);

  close = () => this.loadingSubject.next(false);

  listen = (): Observable<boolean> => this.loadingSubject.asObservable();
}
