import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../../core/models/entities/company/company';
import { CompanyEnableDisable } from '../../../core/models/entities/company/company-enable-disable';
import { CompanyService } from '../../../core/services/company/company.service';

@Component({
  selector: 'app-modal-activate-disable-company',
  templateUrl: './modal-activate-disable-company.component.html',
  styleUrls: ['./modal-activate-disable-company.component.scss']
})
export class ModalActivateDisableCompanyComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() open: boolean;
  @Input() company: Company;
  @Output() resModal = new EventEmitter();
  form: FormGroup;
  reasonsChangeStatus: any[];
  modalTitle: string;

  constructor(
    private companyService: CompanyService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.open) {
      this.verifyState();
      await this.getDisableReasons();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      motivo: [null, Validators.required],
      observacao: [null]
    });
  }

  verifyState() {
    if (this.company?.indAtivo) {
      this.modalTitle = 'Desativar Empresa';
    } else {
      this.modalTitle = 'Ativar Empresa';
    }
  }

  async enableDisable() {
    const { motivo, observacao } = this.form.value;
    const object: CompanyEnableDisable = {
      id: this.company.id,
      ativar: !this.company?.indAtivo,
      idMotivo: motivo?.id,
      observacao
    };
    if (this.form.valid) {
      const response = await lastValueFrom(this.companyService.enable(object));
      if (response?.mensagem) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          key: 'global-toast',
          sticky: true,
          detail: response?.mensagem
        });
        this.clear();
        this.resModal.emit({ send: true, close: true });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Campo obrigatório',
        key: 'global-toast',
        sticky: true,
        detail: 'Você precisa escolher um motivo para a exclusão da empresa'
      });
    }
  }

  async getDisableReasons() {
    const response = await lastValueFrom(
      this.companyService.listdesableReasons()
    );
    if (response?.dados) {
      this.reasonsChangeStatus = response.dados;
    }
  }

  close() {
    this.clear();
    this.resModal.emit({ close: true });
  }

  clear() {
    this.form.reset();
    this.company = undefined as any;
    this.reasonsChangeStatus = [];
  }

  ngOnDestroy() {
    this.clear();
  }
}
