import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLogged()) {
      const hashAutorizacao = route.queryParams['hashAutorizacao'];
      let queryParams = {};

      if (hashAutorizacao) {
        queryParams = {
          queryParams: {
            hashAutorizacao
          }
        };
      }
      return this.router.createUrlTree(['/inicio'], queryParams);
    }
    return !this.authService.isLogged();
  }
}
