import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { InputMask } from 'primeng/inputmask';
import { InputText } from 'primeng/inputtext';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { StepService } from 'src/app/core/services/step/step.service';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-form-code-validation',
  templateUrl: './form-code-validation.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, InputText, InputMask, Button],
  styleUrls: ['./form-code-validation.component.scss']
})
export class FormCodeValidationComponent implements OnInit {
  form = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    code: [null, [Validators.required, Validators.maxLength(8)]]
  });

  mensagem: string;

  constructor(
    private stepService: StepService,
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  get email() {
    return this.form.get('email');
  }

  ngOnInit(): void {
    const step = this.stepService.find(1);
    if (step) {
      const { email, mensagem } = step?.data;
      this.mensagem = mensagem;
      this.form.patchValue({ email });
      this.form.get('email')?.disable();
    }
  }

  async next() {
    if (this.form.valid) {
      const { code, email } = this.form.getRawValue();
      if (code && email) {
        const response = await lastValueFrom(
          this.authService.validateCode(code, email)
        );

        if (response.mensagem) {
          this.messageService.add({
            key: 'global-toast',
            severity: 'success',
            summary: 'Sucesso',
            detail: response.mensagem,
            life: 4000
          });
          this.stepService.next();
        }
      }
    }
  }

  async resend() {
    if (this.email?.value) {
      const response = await lastValueFrom(
        this.authService.emailValidation(this.email?.value)
      );
      if (response?.mensagem) {
        this.messageService.add({
          key: 'global-toast',
          severity: 'success',
          summary: 'Sucesso',
          detail: response.mensagem,
          life: 4000
        });
      }
    }
  }
}
