import { Component, Input, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  template: `
    <section id="dashboard-container" [style.min-height]="minHeight">
      <div class="wrapper">
        <div id="dashboard-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </section>
  `,
  standalone: true,
  imports: [RouterOutlet],
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  @Input() minHeight = `min-height: calc(100vh - (90px + 47px))`;
  constructor() {}
}
