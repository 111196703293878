import { TemplateLoginComponent } from '@shared/components/template-login/template-login.component';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ValidarChaveSenhaResponse } from 'src/app/core/models/responses/validar-chave-senha/validar-chave-senha-response';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';

import { filter, lastValueFrom } from 'rxjs';

import { SenhaFormAlteracaoComponent } from './senha-form-alteracao/senha-form-alteracao.component';

@Component({
  selector: 'app-senha-alteracao',
  template: `
    <app-template-login *ngIf="externalPage" [title]="title">
      <app-form-senha-alteracao
        [chaveExpirada]="chaveExpirada"
        [email]="dadosChaveValidada.email"
        [login]="dadosChaveValidada.login"
        (formChanges)="submit($event)"></app-form-senha-alteracao>
    </app-template-login>
    <p-toast position="top-right"></p-toast>

    <div
      *ngIf="!externalPage"
      class="container-change-passwod-inside grid grid-nogutter col-6">
      <h1 class="form-title md:col-9 col-10 md:mt-4 mt-4 pl-0 mb-2">
        {{ title }}
      </h1>
      <app-form-senha-alteracao
        [externalPage]="externalPage"
        (formChanges)="submit($event)"></app-form-senha-alteracao>
    </div>
  `,
  styleUrls: ['./senha-alteracao.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TemplateLoginComponent,
    ReactiveFormsModule,
    InputTextModule,
    ToastModule,
    SenhaFormAlteracaoComponent
  ]
})
export class SenhaAlteracaoComponent implements OnInit {
  title = 'Alteração de Senha';
  toggleNewPassword = false;
  toggleConfirmPassword = false;
  submitted = false;
  externalPage: boolean;
  chave: string;
  dadosChaveValidada: ValidarChaveSenhaResponse;
  chaveExpirada: boolean;
  validityKey: boolean;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
    this.listenRouter();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.chave = params['chave'];
      if (this.externalPage) {
        void this.validateKey();
      }
    });
  }

  listenRouter() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.externalPage = !event.url.includes('alterar-senha-logado');
      });
  }

  async validateKey() {
    const response = await lastValueFrom(
      this.authService.validateKeyPassword(this.chave)
    );
    if (response.dados) {
      this.dadosChaveValidada = response.dados;
    } else if (response.error) {
      this.chaveExpirada = true;
    }
  }

  handlerMessageSuccess(response: any) {
    if (response.dados.mensagem) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        sticky: true,
        detail: response.dados.mensagem
      });
    }
  }

  async submit(form: FormGroup) {
    const { newPassword, confirmNewPassword } = form.value;
    if (form.valid) {
      const logged = {
        novaSenha: newPassword,
        confirmarSenha: confirmNewPassword
      };

      const notLogged = {
        novaSenha: newPassword,
        confirmarSenha: confirmNewPassword,
        idUsuario: this.dadosChaveValidada?.idUsuario,
        chave: this.chave
      };

      const response = await lastValueFrom(
        this.authService.changePassword(
          this.externalPage ? notLogged : logged,
          this.externalPage
        )
      );
      this.handlerMessageSuccess(response);
      if (!response.error) {
        const user = this.authService.getUserValue();
        if (user.indTrocaSenhaObrigatoria) {
          user.indTrocaSenhaObrigatoria = false;
          this.localStorageService.updateProperty('user', user);
        }
        if (this.externalPage) {
          await this.router.navigate(['login']);
        }
      }
    } else {
      this.messageService.add({
        sticky: true,
        severity: 'warn',
        summary: 'Erro',
        detail:
          'A senha deve conter no mínimo 8 caracteres \n' +
          'alfanuméricos: letras maiúsculas, minúsculas, \n' +
          'números e caracteres especiais.'
      });
    }
  }
}
