<form [formGroup]="form" class="container mt-4">
  <div class="row mb-4">
    <label class="custom-label" for="email">E-mail:</label>
    <input
      class="custom-input"
      pInputText
      id="email"
      formControlName="email"
      type="text" />
  </div>
  <div class="text-green mb-4">
    {{ mensagem }}
  </div>
  <div class="row mb-3">
    <label class="custom-label" for="code">Código:</label>
    <p-inputMask
      class="code-input"
      id="code"
      formControlName="code"
      mask="99999999"></p-inputMask>
    <p-button [disabled]="form.invalid" class="btn-verde ml-2" (click)="next()">
      Validar código
    </p-button>
  </div>
  <span class="text-blue" (click)="resend()">
    Não recebeu ou expirou? Clique aqui para reenviar o código.
  </span>
</form>
