import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup
} from '@angular/forms';

export function markAllFields(
  form: AbstractControl,
  action: 'dirty' | 'touched' | 'invalid'
): void {
  if (form instanceof FormControl) {
    if (action === 'dirty') form.markAsDirty();
    if (action === 'touched') form.markAsTouched();
    if (action === 'invalid' && !form.valid) form.markAsDirty(); // Marcar como dirty se inválido
  } else if (form instanceof FormGroup || form instanceof FormArray) {
    Object.values(form.controls).forEach(control =>
      markAllFields(control, action)
    );
  }
}
