import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Company } from '../../models/entities/company/company';
import { CompanyDeleteReason } from '../../models/entities/company/company-delete-reason';
import { CompanyDisableReason } from '../../models/entities/company/company-disable-reason';
import { CompanyEnableDisable } from '../../models/entities/company/company-enable-disable';
import { DefaultResponse } from '../../models/responses/default-response';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private url = `${environment.baseUrl}/empresas`;

  constructor(private httpClient: HttpClient) {}

  list(listarTodas = false): Observable<DefaultResponse<Company>> {
    return this.httpClient
      .get<DefaultResponse<Company>>(`${this.url}?listarTodas=${listarTodas}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listByUsersCnpjs(
    listarTodas = false,
    usuarios: any = null,
    cnpjs: any = null
  ): Observable<DefaultResponse<Company>> {
    return this.httpClient
      .get<
        DefaultResponse<Company>
      >(`${this.url}?listarTodas=${listarTodas}&idUsuarios=${usuarios}&cnpjs=${cnpjs}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listUserCompanies(idUsuario: number): Observable<DefaultResponse<Company>> {
    return this.httpClient
      .get<DefaultResponse<Company>>(`${this.url}?idUsuario=${idUsuario}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listDeleteReasons(): Observable<DefaultResponse<CompanyDeleteReason>> {
    return this.httpClient
      .get<DefaultResponse<CompanyDeleteReason>>(`${this.url}/motivo-exclusao`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  delete(obj: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.url}/solicitar-exclusao`, obj)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  register(company) {
    return this.httpClient.post<any>(`${this.url}`, company).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  update(company) {
    return this.httpClient.put<any>(`${this.url}/${company.id}`, company).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  enable(company: any) {
    const { idMotivo, observacao, id, indAtivo } = company;
    const ativar = indAtivo === false ? true : false;
    return this.httpClient
      .put<any>(`${this.url}/${id}/ativacao`, {
        ativar,
        idMotivo,
        observacao
      })
      .pipe(
        catchError(err => {
          return of(err);
        })
      );
  }

  listdesableReasons(): Observable<DefaultResponse<CompanyDisableReason>> {
    return this.httpClient
      .get<CompanyDisableReason>(`${this.url}/motivo-inativacao`)
      .pipe(
        catchError(err => {
          return of(err);
        })
      );
  }

  getMatriz(cnpj: string): Observable<any> {
    // return of({ dados: { cnpj: 9999999999 } });
    return this.httpClient.get<any>(`${this.url}/cnpj-matriz/${cnpj}`).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  download(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/download`, { responseType: 'blob' })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  downloadBackupNfes(empresas: number[]): Observable<any> {
    return this.httpClient
      .post(`${this.url}/solicita-backup-nfe`, { empresas })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  validate(cnpj: string): Observable<DefaultResponse<any>> {
    return this.httpClient
      .get<DefaultResponse<any>>(`${this.url}/${cnpj}/validar-existente`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getLog(companyId: number): Observable<DefaultResponse<any>> {
    return this.httpClient
      .get<any>(`${this.url}/${companyId}/log-ativacao`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
